import {
  OnDestroy,
  OnInit,
  Component,
  Input,
  HostBinding,
  HostListener,
  Inject,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { select, Store } from '@ngrx/store';
import {
  WebsocketGatewayService,
  ENVIRONMENT_CONFIG,
  EnvironmentConfig,
} from '@ipreo/northstar';
import { DesktopNotificationService } from '../../services/desktop-notifications.service';
import { getMessagesStatusStoreState, State } from '../../state/reducer';
import { MessagesStatusState } from '../../state/messages-status/state';
import {
  SetAlertsAppCodeAction,
  SetAlertsTokenAction,
} from '../../state/config/actions';
import { MessagesStatusBumpAction } from '../../state/messages-status/actions';
import { Message } from '../../types';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'northstar-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'void',
        style({
          right: '-420px',
        })
      ),
      transition('void <=> *', animate('300ms 0s ease')),
    ]),
  ],
})
export class AlertsComponent implements OnDestroy, OnInit {
  @HostBinding('class.c-header-item-link')
  headerClass = true;

  @Input()
  public token: Observable<string>;

  @Input()
  public appCode: string;

  public messagesStatus$: Observable<MessagesStatusState> = this.store.pipe(
    select(getMessagesStatusStoreState),
    distinctUntilChanged()
  );

  public paneVisible = false;

  public cupcakeMajorVersion: number;

  private tokenSubscription: Subscription;
  private websocketSubscription: Subscription;

  private wasInside = false;

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.paneVisible = false;
    }
    this.wasInside = false;
  }

  constructor(
    private store: Store<State>,
    private websocketGatewayService: WebsocketGatewayService,
    private notificationService: DesktopNotificationService,
    @Inject(ENVIRONMENT_CONFIG) config: EnvironmentConfig
  ) {
    this.cupcakeMajorVersion = config.northstar.cupcakeMajorVersion;
  }

  ngOnInit() {
    this.store.dispatch(new SetAlertsAppCodeAction(this.appCode));

    this.tokenSubscription = this.token.subscribe((token) => {
      this.store.dispatch(new SetAlertsTokenAction(token));
    });

    this.websocketSubscription = this.websocketGatewayService
      .subscribe('alerts')
      .subscribe((message: Message) => {
        this.store.dispatch(new MessagesStatusBumpAction());
        this.notificationService.notify(message);
      });
  }

  ngOnDestroy(): void {
    if (this.tokenSubscription) {
      this.tokenSubscription.unsubscribe();
    }
    if (this.websocketSubscription) {
      this.websocketSubscription.unsubscribe();
    }

    this.websocketGatewayService.unsubscribe('alerts');
  }

  public togglePane() {
    this.paneVisible = !this.paneVisible;
  }
}
