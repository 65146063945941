<div class="c-dropdown"
     (clickOutside)="toggleDropdown()"
     [clickOutsideEnabled]="isDropdownVisible"
>
  <span *ngIf="params.hasOwnProperty('path')" class="split-dropdown">
    <a [routerLink]="params.path" queryParamsHandling="merge"
      class="c-header-item-link dropdown-link"
      [ngClass]="(params.classes || []).join(' ') + ((active | async) ? 'c-header-item-link-active' : '')"
      [attr.data-automation-e2e-selector]="
        'nav-item-' + (params.hasOwnProperty('name') ? params.name : '')
      ">
      <ng-container *ngTemplateOutlet="linkInnerContent; context: { params: params }"></ng-container>
    </a>
    <a (click)="toggleDropdown()" href="javascript:void(0);" class="c-header-item-link dropdown-toggle"
       [ngClass]="(params.classes || []).join(' ') + ((active | async) ? 'c-header-item-link-active' : '')"
       [attr.data-automation-e2e-selector]="
        'header-toggle-' + (params.hasOwnProperty('name') ? params.name : '')
      ">
      <i class="fa fa-caret-down" aria-hidden="true"></i>
    </a>
  </span>
  <span *ngIf="params.hasOwnProperty('event')" class="split-dropdown">
    <a *ngIf="params.hasOwnProperty('event')" href="javascript:void(0);" (click)="params.event($event)"
      class="c-header-item-link dropdown-link"
      [ngClass]="(params.classes || []).join(' ') + ((active | async) ? 'c-header-item-link-active' : '')"
      [attr.data-automation-e2e-selector]="
        'nav-item-' + (params.hasOwnProperty('name') ? params.name : '')
      ">
      <ng-container *ngTemplateOutlet="linkInnerContent; context: { params: params }"></ng-container>
    </a>
    <a (click)="toggleDropdown()" href="javascript:void(0);" class="c-header-item-link dropdown-toggle"
       [ngClass]="(params.classes || []).join(' ') + ((active | async) ? 'c-header-item-link-active' : '')"
       [attr.data-automation-e2e-selector]="
        'header-toggle-' + (params.hasOwnProperty('name') ? params.name : '')
      ">
      <i class="fa fa-caret-down" aria-hidden="true"></i>
    </a>
  </span>
  <a *ngIf="!params.hasOwnProperty('event') && !params.hasOwnProperty('path')" href="javascript:void(0);" (click)="toggleDropdown()"
    class="c-header-item-link"
    [ngClass]="(params.classes || []).join(' ') + ((active | async) ? 'c-header-item-link-active' : '')"
    [attr.data-automation-e2e-selector]="
      'nav-item-' + (params.hasOwnProperty('name') ? params.name : '')
    ">
    <ng-container *ngTemplateOutlet="linkInnerContent; context: { params: params }"></ng-container>
    <i class="fa fa-caret-down c-m-left-sm" aria-hidden="true" [attr.data-automation-e2e-selector]="
        'header-toggle-' + (params.hasOwnProperty('name') ? params.name : '')
      "></i>
  </a>
  <ul class="c-dropdown-list c-dropdown-list-below" [ngClass]="{'dropdown-show': isDropdownVisible}" [ngStyle]="{'width.px': 200}">
    <li *ngFor="let itemParams of params.menuItems" class="c-dropdown">
      <a *ngIf="itemParams.hasOwnProperty('path')" [routerLink]="itemParams.path"
        [ngClass]="(itemParams.classes || []).join(' ')" [attr.data-automation-e2e-selector]="
          'nav-item-' +
          (itemParams.hasOwnProperty('name') ? itemParams.name : '')
        ">
        <ng-container *ngTemplateOutlet="linkInnerContent; context: { params: itemParams }"></ng-container>
      </a>
      <a *ngIf="itemParams.hasOwnProperty('event')" href="javascript:void(0);" (click)="itemParams.event($event)"
        [ngClass]="(itemParams.classes || []).join(' ')" [attr.data-automation-e2e-selector]="
          'nav-item-' +
          (itemParams.hasOwnProperty('name') ? itemParams.name : '')
        ">
        <ng-container *ngTemplateOutlet="linkInnerContent; context: { params: itemParams }"></ng-container>
      </a>
    </li>
  </ul>
</div>

<ng-template #linkInnerContent let-params="params">
  <ng-container *ngIf="params.hasOwnProperty('name')">
    <span *ngIf="params.icon" [ngClass]="'fa fa-' + params.icon" aria-hidden="true"
      [attr.data-automation-e2e-selector]="'nav-item-icon-' + params.name"></span>
    <span [attr.data-automation-e2e-selector]="'nav-item-title-' + params.name">{{ params.name }}</span>
  </ng-container>
  <ng-container *ngIf="params.hasOwnProperty('innerHtml')">
    <span [innerHtml]="params.innerHtml"></span>
  </ng-container>
</ng-template>