import { Injectable, Inject } from '@angular/core';
import { Sockette } from './sockette';
import { ReplaySubject, Observable, Subject } from 'rxjs';
import { EnvironmentConfig } from '../../types';
import { ENVIRONMENT_CONFIG } from '../../tokens';
import { ChannelSubscription } from './channel-subscription';

export enum WebsocketGatewayAuthType {
  Alerts = 'Alerts',
  Account = 'Account',
  CAS = 'CAS',
  JWT = 'JWT',
}

@Injectable()
export class WebsocketGatewayService {
  private url: string;
  private client: Sockette;
  private subscriptions: ChannelSubscription[] = [];

  public starting$: Observable<Event>;
  public closing$: Observable<Event>;
  public error$: Observable<Event>;

  private startingSubject = new ReplaySubject<Event>(1);
  private closeSubject = new Subject<Event>();
  private errorSubject = new Subject<Event>();

  private userRequestedClose = false;

  constructor(@Inject(ENVIRONMENT_CONFIG) config: EnvironmentConfig) {
    this.url = config.northstar.host.ws;

    this.starting$ = this.startingSubject.asObservable();
    this.closing$ = this.closeSubject.asObservable();
    this.error$ = this.errorSubject.asObservable();
  }

  public connect(authType: WebsocketGatewayAuthType, token: string) {
    if (this.client) {
      this.userRequestedClose = true;
      this.client.close();
    }

    this.client = new Sockette(
      `${this.url}?authType=${authType}&token=${token}`,
      {
        timeout: 1000,
        maxAttempts: 10,
        onopen: this.onopen.bind(this),
        onmessage: this.onmessage.bind(this),
        onmaximum: this.onmaximum.bind(this),
        onclose: this.onclose.bind(this),
        onerror: this.onerror.bind(this),
      }
    );
  }

  public isSocketCreated(): boolean {
    return !!this.client;
  }

  public disconnect() {
    if (this.client) {
      this.userRequestedClose = true;
      this.client.close();
    }
  }

  public subscribe(channelName: string) {
    let channelSub = this.subscriptions.find((sub) => {
      return sub.channelName === channelName;
    });

    if (channelSub) {
      return channelSub.subject.asObservable();
    }

    channelSub = new ChannelSubscription(channelName);
    this.subscriptions.push(channelSub);

    this.startingSubject.subscribe(() => {
      this.client.json({
        action: 'subscribe',
        channelName,
      });
    });

    return channelSub.subject.asObservable();
  }

  public unsubscribe(channelName: string) {
    const channelSub = this.subscriptions.find((sub) => {
      return sub.channelName === channelName;
    });

    if (channelSub && this.client) {
      this.client.json({
        action: 'unsubscribe',
        channelName,
      });
    }
  }

  private onmessage(messageEvent: MessageEvent) {
    const { event, channelName, payload } = JSON.parse(messageEvent.data);

    if (event === 'error') {
      console.log('Websocket Gateway Error: ', payload);
      return;
    }

    const channelSub = this.subscriptions.find(
      (sub) => sub.channelName === channelName
    );

    if (channelSub) {
      switch (event) {
        case 'unsubscribed':
          if (payload.statusCode === 200) {
            channelSub.subject.complete();
            this.subscriptions.splice(
              this.subscriptions.indexOf(channelSub),
              1
            );
          } else {
            channelSub.subject.error(new Error(payload.message));
          }
          break;
        case 'subscribed':
          if (payload.statusCode !== 200) {
            channelSub.subject.error(new Error(payload.message));
          }
          break;
        case 'message':
          channelSub.subject.next(payload);
          break;
      }
    }
  }

  private onopen(event: Event) {
    this.startingSubject.next(event);
  }

  private onmaximum(event: Event) {
    this.errorSubject.next(event);
  }

  private onclose(event: Event) {
    this.closeSubject.next(event);
    if (!this.userRequestedClose) {
      this.client.reconnect();
    }
    this.userRequestedClose = false;
  }

  private onerror(event: Event) {
    this.errorSubject.next(event);
  }
}
