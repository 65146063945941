import { Injectable, ErrorHandler } from '@angular/core';
import { LogService } from './log.service';

@Injectable()
export class NorthstarErrorHandler extends ErrorHandler {
  constructor(private _logService: LogService) {
    super();
  }

  public handleError(error: Error): void {
    const payload = error.stack || error.toString();
    this._logService.error(error.message, payload);

    super.handleError(error);
  }
}
