import { InjectionToken } from '@angular/core';
import { EnvironmentConfig, DevModule } from './types';

export const OIDC_SETTINGS = new InjectionToken(
  '@ipreo/northstar OIDC settings'
);

export const APP_NAME = new InjectionToken('@ipreo/northstar App name');

export const APP_VERSION = new InjectionToken('@ipreo/northstar App version');

export const ENVIRONMENT_CONFIG = new InjectionToken<EnvironmentConfig>(
  '@ipreo/northstar env'
);

export const DEV_MODULES = new InjectionToken<DevModule[]>(
  '@ipreo/northstar devModules'
);
