import { Injectable, Inject } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { EnvironmentConfig, ENVIRONMENT_CONFIG } from '@ipreo/northstar';
import { mergeMap } from 'rxjs/operators';
import {
  MessagesStatusResponse,
  MessagesResponse,
  ErrorResponse,
  MessagesStatusSuccessResponse,
  Message,
  MessageType,
  MessageTypeResponse,
  MessageTypePreference,
  MessageTypePreferenceResponse,
  MessageTypePreferenceRequest,
  Transport,
  TransportResponse,
} from '../types';
import { ConfigState } from '../state/config/state';

const API_HOSTS = {
  dev: 'https://staging.alerts.ipreo.com',
  staging: 'https://staging.alerts.ipreo.com',
  prod: 'https://alerts.ipreo.com',
};

@Injectable()
export class AlertsService {
  private baseUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(ENVIRONMENT_CONFIG) apiConfig: EnvironmentConfig
  ) {
    if (!apiConfig.northstar.env) {
      console.error(
        'You must define `northstar.env` in your northstar config to use the NorthstarAlertsModule.'
      );
    } else {
      this.baseUrl = API_HOSTS[apiConfig.northstar.env] + '/app/alerts/api';
    }
  }

  public getStatus(
    config: ConfigState
  ): Observable<MessagesStatusSuccessResponse> {
    return this.get<MessagesStatusResponse>('/messages/status', config).pipe(
      mergeMap((res: HttpResponse<MessagesStatusResponse>) => {
        if (res.status !== 200) {
          const errorResponse = res.body as ErrorResponse;
          return throwError(errorResponse.developerMessage);
        }

        return of(res.body as MessagesStatusSuccessResponse);
      })
    );
  }

  public getMessages(
    options: { size?: number; from?: number },
    config: ConfigState
  ): Observable<Message[]> {
    const params = {
      size: `${options.size || 20}`,
      from: `${options.from || 0}`,
    };

    return this.get<MessagesResponse>('/messages', config, params).pipe(
      mergeMap((res: HttpResponse<MessagesResponse>) => {
        if (res.status !== 200) {
          const errorResponse = res.body as ErrorResponse;
          return throwError(errorResponse.developerMessage);
        }

        return of(res.body as Message[]);
      })
    );
  }

  public getMessageTypes(config: ConfigState): Observable<MessageType[]> {
    return this.get<MessageTypeResponse>('/message-types', config).pipe(
      mergeMap((res: HttpResponse<MessageTypeResponse>) => {
        if (res.status !== 200) {
          const errorResponse = res.body as ErrorResponse;
          return throwError(errorResponse.developerMessage);
        }

        return of(res.body as MessageType[]);
      })
    );
  }

  public getPreferences(
    config: ConfigState
  ): Observable<MessageTypePreference[]> {
    return this.get<MessageTypePreferenceResponse>('/preferences', config).pipe(
      mergeMap((res: HttpResponse<MessageTypePreferenceResponse>) => {
        if (res.status !== 200) {
          const errorResponse = res.body as ErrorResponse;
          return throwError(errorResponse.developerMessage);
        }

        return of(res.body as MessageTypePreference[]);
      })
    );
  }

  public getTransports(config: ConfigState): Observable<Transport[]> {
    return this.get<TransportResponse>('/transports', config).pipe(
      mergeMap((res: HttpResponse<TransportResponse>) => {
        if (res.status !== 200) {
          const errorResponse = res.body as ErrorResponse;
          return throwError(errorResponse.developerMessage);
        }

        return of(res.body as Transport[]);
      })
    );
  }

  public setPreference(
    request: MessageTypePreferenceRequest,
    config: ConfigState
  ): Observable<void> {
    const body = {
      appCode: config.appCode,
      createPreferenceIdentifiers: [request],
    };

    return this.http
      .post(`${this.baseUrl}/preferences`, body, {
        observe: 'response',
        headers: {
          Authorization: `Bearer ${config.token}`,
        },
      })
      .pipe(
        mergeMap((res: HttpResponse<ErrorResponse>) => {
          if (res.status !== 200) {
            const errorResponse = res.body;
            return throwError(errorResponse.developerMessage);
          }
          return of(undefined);
        })
      );
  }

  public readMessages(
    deliveryIds: number[],
    read: boolean,
    config: ConfigState
  ): Observable<void> {
    const body = {
      appCode: config.appCode,
      alerts: deliveryIds.map((deliveryId) => ({
        deliveryId,
        isRead: read,
      })),
    };

    return this.http
      .put(`${this.baseUrl}/messages/deliveries/read`, body, {
        observe: 'response',
        headers: {
          Authorization: `Bearer ${config.token}`,
        },
      })
      .pipe(
        mergeMap((res: HttpResponse<ErrorResponse>) => {
          if (res.status !== 200) {
            const errorResponse = res.body;
            return throwError(errorResponse.developerMessage);
          }
          return of(undefined);
        })
      );
  }

  public readAllMessages(config: ConfigState): Observable<void> {
    const body = {
      appCode: config.appCode,
    };

    return this.http
      .put(`${this.baseUrl}/messages/deliveries/read-all`, body, {
        observe: 'response',
        headers: {
          Authorization: `Bearer ${config.token}`,
        },
      })
      .pipe(
        mergeMap((res: HttpResponse<ErrorResponse>) => {
          if (res.status !== 200) {
            const errorResponse = res.body;
            return throwError(errorResponse.developerMessage);
          }
          return of(undefined);
        })
      );
  }

  private get<T>(
    path: string,
    config: ConfigState,
    params: { [key: string]: string } = {}
  ) {
    return this.http.get<T>(`${this.baseUrl}${path}`, {
      observe: 'response',
      headers: {
        Authorization: `Bearer ${config.token}`,
      },
      params: {
        ...params,
        appCode: config.appCode,
      },
    });
  }
}
