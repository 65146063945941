import { Component, Input, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderLinkParams } from '../header.component';

@Component({
  selector: 'polaris-header-link',
  templateUrl: './header-link.component.html'
})
export class HeaderLinkComponent {
  @HostBinding('class.c-header-item')
  item = true;

  @Input()
  public params: HeaderLinkParams;

  constructor(private router: Router) {}

  public isLinkActive(path: string) {
    const nonQueryUrl = this.router.url.split('?')[0];
    return path === nonQueryUrl;
  }
}
