import {
  Injectable,
  Injector,
  Inject,
  ViewContainerRef,
  ComponentRef,
} from '@angular/core';

import { CardType, CardModuleConfig } from '../../types';
import { LogService } from '../../services/log/log.service';
import { PolarisService } from '../../services/polaris.service';
import { EnvironmentConfig, ModuleFactories } from '../../types';
import { ENVIRONMENT_CONFIG } from '../../tokens';
import { WrapperInjector } from '../../services/util/wrapper-injector';
import { CompilationService } from '../../services/compilation.service';

@Injectable()
export class CardService {
  constructor(
    private polarisService: PolarisService,
    private compilationService: CompilationService,
    private injector: Injector,
    private logService: LogService,
    @Inject(ENVIRONMENT_CONFIG) private config: EnvironmentConfig
  ) {}

  public loadModule(
    view: ViewContainerRef,
    context: CardType,
    override: string
  ): Promise<ComponentRef<unknown>> {
    return this._wrapLoading(context, (cardInjector: Injector) => {
      return this.polarisService.loadAndCreateAngularComponent(
        view,
        context.name,
        context.version,
        cardInjector,
        (manifest) => {
          const moduleConfig: CardModuleConfig = manifest.platforms[
            override
          ] as CardModuleConfig;
          const ngConfig: CardModuleConfig = manifest.platforms['angular'];

          return {
            moduleName:
              (moduleConfig && moduleConfig.moduleName) || ngConfig.moduleName,
            selector:
              (moduleConfig && moduleConfig.selector) || ngConfig.selector,
          };
        }
      );
    });
  }

  public loadModuleByUrl(
    view: ViewContainerRef,
    url: string,
    moduleName: string,
    selector: string,
    params: Record<string, unknown> = {}
  ): Promise<ComponentRef<unknown>> {
    return this._wrapLoading(
      {
        identifier: '',
        name: moduleName,
        params: params,
        version: '',
      } as CardType,
      (cardInjector: Injector) => {
        return this.polarisService.loadAndCreateAngularComponentByUrl(
          view,
          url,
          moduleName,
          selector,
          cardInjector
        );
      }
    );
  }

  public instantiateContent(
    view: ViewContainerRef,
    context: CardType,
    factories: ModuleFactories
  ): Promise<ComponentRef<unknown>> {
    return this._wrapLoading(context, (cardInjector: Injector) =>
      this.compilationService.instantiateComponent(
        view,
        factories,
        cardInjector
      )
    );
  }

  private _wrapLoading(
    context: CardType,
    load: (Injector) => Promise<ComponentRef<unknown>>
  ): Promise<ComponentRef<unknown>> {
    const moduleLogData = { module: `${context.name}@${context.version}` };
    this.logService.event('Module load start', moduleLogData);

    const cardInjector = this._createInjector(context, this.injector);

    return load(cardInjector)
      .catch((err) => {
        this.logService.event('Module load failure', {
          ...moduleLogData,
          error: err,
        });
        throw err;
      })
      .then((r) => {
        this.logService.event('Module load success', moduleLogData);
        return r;
      });
  }

  private _createInjector(context: CardType, parentInjector: Injector) {
    return new WrapperInjector(context.name, context.version).getInjector(
      parentInjector,
      {
        ...this.config,
        params: { ...((context && context.params) || {}) },
      }
    );
  }
}
