import { Action } from '@ngrx/store';

export const SETTINGS_FETCH = '[northstar] Fetch Settings';
export const SETTINGS_FETCH_SUCCESS = '[northstar] Fetch Settings Success';
export const SETTINGS_FETCH_ERROR = '[northstar] Fetch Settings Error';

export const SETTINGS_SAVE = '[northstar] Settings Save';

export const SETTINGS_SAVE_SUCCESS = '[northstar] Settings Save Success';
export const SETTINGS_SAVE_ERROR = '[northstar] Settings Save Error';

/* tslint:disable:max-classes-per-file */

export class SettingsFetchAction implements Action {
  public readonly type = SETTINGS_FETCH;
}

export class SettingsFetchSuccessAction implements Action {
  public readonly type = SETTINGS_FETCH_SUCCESS;

  constructor(public payload: Record<string, unknown>) {}
}

export class SettingsFetchErrorAction implements Action {
  public readonly type = SETTINGS_FETCH_ERROR;

  constructor(public payload: string) {}
}

export class SettingsSaveAction implements Action {
  public readonly type = SETTINGS_SAVE;

  constructor(
    public payload: { requestId: string; path: string; setting: unknown }
  ) {}
}

export class SettingsSaveSuccessAction implements Action {
  public readonly type = SETTINGS_SAVE_SUCCESS;

  constructor(
    public payload: { requestId: string; settings: Record<string, unknown> }
  ) {}
}

export class SettingsSaveErrorAction implements Action {
  public readonly type = SETTINGS_SAVE_ERROR;

  constructor(public payload: { requestId: string; error: string }) {}
}

export type SettingsActions =
  | SettingsFetchAction
  | SettingsFetchSuccessAction
  | SettingsFetchErrorAction
  | SettingsSaveAction
  | SettingsSaveSuccessAction
  | SettingsSaveErrorAction;
