import { Component, OnInit, Inject } from '@angular/core';
import {
  Router,
  RouteConfigLoadStart,
  NavigationCancel,
  NavigationEnd,
  RoutesRecognized,
} from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { trigger, style, transition, animate } from '@angular/animations';
import {
  Observable,
  BehaviorSubject,
  combineLatest,
  Subscription,
  Subject,
} from 'rxjs';
import { map, filter, startWith, distinctUntilChanged } from 'rxjs/operators';
import { User } from 'oidc-client';
import {
  Navigation,
  ENVIRONMENT_CONFIG,
  EnvironmentConfig,
  getDevServersState,
  DevServersState,
  DevServer,
  RouteManifest,
  FeaturesState,
} from '@ipreo/northstar';
import {
  AuthService,
  getFeaturesState,
  RoutingService,
  NavigationService,
} from '@ipreo/northstar';
import { Store, select } from '@ngrx/store';
import { State } from './state/reducer';
import {
  HeaderItemType,
  HeaderGroup,
  HeaderItem,
  HeaderLinkParams,
} from './shared/header/header.component';

interface DevServerMenuItem {
  name: string;
  env: 'local' | 'qx' | 'uat' | 'prod';
  releaseVersion: string;
}

@Component({
  selector: 'polaris-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({
          opacity: '0',
        }),
        animate(
          '500ms ease-in',
          style({
            opacity: '1',
          })
        ),
      ]),
    ]),
    trigger('fadeOut', [
      transition(':leave', [
        style({
          opacity: '1',
        }),
        animate(
          '500ms ease-in',
          style({
            opacity: '0',
          })
        ),
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit {
  public title = 'home';
  public isLoading: Observable<boolean>;

  public user = new BehaviorSubject<User>(null);
  public token = new BehaviorSubject<string>(null);

  public serverInfo = new Subject<HeaderItem>();

  public headerDropdownSubmenu: HeaderLinkParams[] = [
    {
      event: () =>
        window.open(
          'https://www.spglobal.com/en/legal/gcp-products-terms-of-use',
          '_blank'
        ),
      name: 'Terms of Use',
    },
    {
      event: () =>
        window.open('https://www.spglobal.com/en/legal/privacy', '_blank'),
      name: 'Privacy Policy',
    },
    {
      event: () =>
        window.open(
          `${window.location.origin}/legal/disclaimers.html`,
          '_blank'
        ),
      name: 'Disclaimers',
    },
    {
      event: () =>
        window.open(
          `${window.location.origin}/legal/third-party-data.html`,
          '_blank'
        ),
      name: 'Third Party Data',
    },
    {
      event: () => this.logout(),
      name: 'Logout',
    },
  ];
  public clearServerDropdown: HeaderItem;

  public headerItems: Observable<HeaderItem[]> = combineLatest([
    this.polarisNavigation.items.pipe(startWith([] as Navigation[])),
    this.user,
    this.serverInfo,
  ]).pipe(
    map(([navigationItems, user, serverInfo]) => {
      const linkNavItems = navigationItems.filter((item) => item.path !== '/');

      const navItems: HeaderItem[] =
        linkNavItems.length === 1
          ? this.convertNavToHeaderItems(linkNavItems[0].children)
          : this.convertNavToHeaderItems(linkNavItems);

      if (this.config.northstar.env !== 'prod' && serverInfo) {
        navItems.push(serverInfo);
      }

      if (user) {
        const { name, email } = user.profile;

        navItems.push({
          type: HeaderItemType.user,
          group: HeaderGroup.right,
          params: {
            name,
            email,
            menuItems: this.headerDropdownSubmenu,
          },
        });
      }

      return navItems;
    })
  );

  public navigation = this.polarisNavigation.items;

  public loading: Observable<boolean> = this.store.pipe(
    select((state) => !getFeaturesState(state).loaded)
  );

  public serverInfoObs: Observable<DevServer[]> = this.store.pipe(
    select(getDevServersState),
    map((state: DevServersState) =>
      state.servers.filter((server: DevServer) => server.enabled)
    ),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
  );

  private featureRoutes: Observable<RouteManifest[]> = this.store.pipe(
    select(getFeaturesState),
    filter((state: FeaturesState) => state.loaded),
    map((state: FeaturesState) => state.routes)
  );

  private serverInfoSub: Subscription;

  public cupcakeMajorVersion: number;

  constructor(
    private auth: AuthService,
    private polarisRouting: RoutingService,
    private polarisNavigation: NavigationService,
    private store: Store<State>,
    private router: Router,
    private sanitizer: DomSanitizer,
    @Inject(ENVIRONMENT_CONFIG) private config: EnvironmentConfig
  ) {
    if (auth.currentUser) {
      this.user.next(this.auth.currentUser);
      this.token.next(this.auth.currentUser.access_token);
    }

    auth.userLoadedEvent.subscribe((user: User) => {
      this.user.next(user);
      this.token.next(user.access_token);
    });

    this.cupcakeMajorVersion = config.northstar.cupcakeMajorVersion;

    this.serverInfoSub = this.clearServerMenuItem();
  }

  public logout() {
    this.auth.startSignoutMainWindow();
  }

  public ngOnInit() {
    this.isLoading = this.router.events.pipe(
      filter(
        (events) =>
          events instanceof RouteConfigLoadStart ||
          events instanceof NavigationEnd ||
          events instanceof NavigationCancel
      ),
      map((event) => {
        return event instanceof RouteConfigLoadStart;
      })
    );

    this.polarisRouting.start();
  }

  private convertNavToHeaderItems(navigationItems: Navigation[]) {
    return navigationItems.map((navItem) => {
      if (navItem.children && navItem.children.length > 0) {
        return {
          type: HeaderItemType.dropdown,
          group:
            navItem.name === 'Munis' ? HeaderGroup.center : HeaderGroup.right,
          params: {
            name: navItem.name,
            icon: navItem.icon,
            path: navItem.path,
            menuItems: navItem.children.map((childNavItem) => {
              return {
                name: childNavItem.name,
                icon: childNavItem.icon,
                path: childNavItem.path,
              };
            }),
          },
        };
      } else {
        return {
          type: HeaderItemType.link,
          group: navItem.name.startsWith('Deal Query (')
            ? HeaderGroup.right
            : HeaderGroup.center,
          params: {
            name: navItem.name,
            icon: navItem.icon,
            path: navItem.path,
          },
        };
      }
    });
  }

  private clearServerMenuItem() {
    return combineLatest([
      this.featureRoutes,
      this.serverInfoObs,
      this.router.events.pipe(filter((e) => e instanceof RoutesRecognized)),
    ])
      .pipe(
        map(
          ([featureRoutes, serverInfo, routeInfo]: [
            RouteManifest[],
            DevServer[],
            RoutesRecognized
          ]) => {
            let loadedServerInfo = {
              name: '',
              env: '',
              releaseVersion: '',
            };
            const currRoute = Object.prototype.hasOwnProperty.call(
              routeInfo,
              'url'
            )
              ? routeInfo.url.split('?')[0]
              : undefined;

            if (
              featureRoutes &&
              featureRoutes[0]?.module?.params?.platform &&
              currRoute !== '/'
            ) {
              const matchingCurrRoute = featureRoutes.find((route) =>
                currRoute.includes(route.path)
              );
              loadedServerInfo = {
                name: matchingCurrRoute.module.params.platform as string,
                env: matchingCurrRoute.module.params.env as any,
                releaseVersion: matchingCurrRoute.module.version,
              };
            }

            if (serverInfo[0]) {
              const idServer = serverInfo[0].url.split('/');
              let name, env, releaseVersion;

              if (idServer.some((ea) => ea === 'local.muni.ipreo.com:444')) {
                name = idServer[3].split('-')[0];
                env = 'local';
                releaseVersion = '';
              } else if (
                idServer.some(
                  (ea) => ea === 'packages.dev.muni.globalmarkets.ihsmarkit.com'
                )
              ) {
                name = idServer[4].split('-')[0];
                env = idServer[4].split('-')[1].split('.')[0];
                releaseVersion = '';
              }

              loadedServerInfo = {
                name,
                env,
                releaseVersion,
              };
            }

            return loadedServerInfo;
          }
        )
      )
      .subscribe((loadedServerInfo) => {
        const serverName = !loadedServerInfo.name
          ? null
          : `${loadedServerInfo.name} - ${loadedServerInfo.env} ${loadedServerInfo.releaseVersion.length
            ? loadedServerInfo.releaseVersion
            : ''
          }`;
        const serverMenuItem = () => {
          if (!serverName) {
            return null;
          }
          if (loadedServerInfo.name === 'sellside') {
            return {
              type: HeaderItemType.dropdown,
              group: HeaderGroup.right,
              params: {
                name: serverName,
                menuItems: [
                  {
                    name: `Clear Dev Server`,
                    event: () => {
                      this.polarisRouting.start(true);
                    },
                  },
                ],
              },
            };
          } else {
            return {
              type: HeaderItemType.info,
              group: HeaderGroup.right,
              params: {
                name: serverName,
              },
            };
          }
        };

        this.serverInfo.next(serverMenuItem());
      });
  }
}
