import { NgModuleFactory, Injector, Type, NgModuleRef } from '@angular/core';
import { EnvironmentConfig } from '../../types';
import { WrapperInjector } from './wrapper-injector';

export class WrapperNgModuleFactory<T> extends NgModuleFactory<T> {
  constructor(
    private moduleFactory: NgModuleFactory<T>,
    private env: EnvironmentConfig,
    private routeParams: Record<string, unknown> = {},
    private moduleName: string,
    private moduleVersion: string
  ) {
    super();
  }

  public get moduleType(): Type<T> {
    return this.moduleFactory.moduleType;
  }

  public create(parentInjector: Injector | null): NgModuleRef<T> {
    const injector = new WrapperInjector(
      this.moduleName,
      this.moduleVersion
    ).getInjector(parentInjector, {
      ...this.env,
      params: {
        ...this.env.params,
        ...this.routeParams,
      },
    });

    return this.moduleFactory.create(injector);
  }
}
