import { MessagesStatusActions, MessagesStatusActionsUnion } from './actions';
import { MessagesActions, MessagesActionsUnion } from '../messages/actions';
import { MessagesStatusState } from './state';

export const INITIAL_STATE: MessagesStatusState = {
  loaded: false,
  loading: false,
  totalCount: 0,
  unreadCount: 0,
  readCount: 0,
  error: undefined,
};

export function messagesStatusReducer(
  state = INITIAL_STATE,
  action: MessagesStatusActionsUnion | MessagesActionsUnion
): MessagesStatusState {
  switch (action.type) {
    case MessagesStatusActions.Load:
      return {
        ...state,
        loading: true,
      };
    case MessagesStatusActions.LoadSuccess:
      return {
        ...state,
        ...action.payload,
        loaded: true,
        loading: false,
        error: undefined,
      };
    case MessagesStatusActions.LoadFail:
      return {
        ...state,
        loaded: false,
        loading: false,
        totalCount: 0,
        unreadCount: 0,
        readCount: 0,
        error: action.payload,
      };
    case MessagesStatusActions.Bump:
      return {
        ...state,
        totalCount: state.totalCount + 1,
        unreadCount: state.unreadCount + 1,
      };

    case MessagesActions.Read:
      // Optimistic update. Assume reading succeeds.
      // eslint-disable-next-line no-case-declarations
      const readCount =
        action.payload.deliveryIds.length * (action.payload.read ? 1 : -1);

      return {
        ...state,
        unreadCount: Math.max(state.unreadCount - readCount, 0),
        readCount: state.readCount + readCount,
      };
    case MessagesActions.ReadFail:
      // Optimistic update. Assume reading succeeds.
      // eslint-disable-next-line no-case-declarations
      const unreadCount =
        action.payload.deliveryIds.length * (action.payload.read ? -1 : 1);
      return {
        ...state,
        unreadCount: Math.max(state.unreadCount - unreadCount, 0),
        readCount: state.readCount + unreadCount,
      };
    case MessagesActions.ReadAll:
      // Optimistic update. Assume read all succeeds.
      return {
        ...state,
        unreadCount: 0,
        readCount: state.totalCount,
      };
    default:
      return state;
  }
}
