import { CommonModule } from '@angular/common';
import {
  NgModule,
  ModuleWithProviders
} from '@angular/core';
import { ROUTES } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CardContainerComponent } from './components/card-container/card-container.component';
import { CardContainerService } from './components/card-container/card-container.service';
import { CardComponent } from './components/card/card.component';
import { CardService } from './components/card/card.service';
import { WebsocketGatewayService } from './services/websocket-gateway/websocket-gateway.service';
import { PolarisService } from './services/polaris.service';
import { CompilationService } from './services/compilation.service';
import { RoutingService } from './services/routing.service';
import { NavigationService } from './services/navigation.service';
import { AuthService } from './services/auth.service';
import { LoaderService } from './services/loader.service';
import { AuthInterceptor } from './services/auth.interceptor';
import { SettingsService } from './services/settings.service';
import { HMRService } from './services/hmr.service';
import { UserManagerSettings } from 'oidc-client';
import { LogService } from './services/log/log.service';
import { LOG_SETTINGS, LogSettings } from './services/log/log-settings';
import { RouterModule } from '@angular/router';
import { EnvironmentConfig } from './types';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { reducers, metaReducers } from './state/reducer';
import { DevServersEffects } from './state/dev-servers/effects';
import { FeaturesEffects } from './state/features/effects';
import { SettingsEffects } from './state/settings/effects';
import { RouterEffects } from './state/router/effects';
import {
  OIDC_SETTINGS,
  APP_NAME,
  APP_VERSION,
  ENVIRONMENT_CONFIG,
  DEV_MODULES
} from './tokens';
import { DevModule } from './types';

export interface ForRootParams {
  appName: string;
  appVersion: string;
  logSettings: LogSettings;
  devModules: DevModule[];
  oidcSettings: UserManagerSettings;
  envConfig?: EnvironmentConfig;
}

@NgModule({
  entryComponents: [CardComponent],
  declarations: [CardComponent, CardContainerComponent],
  imports: [
    CommonModule,
    RouterModule,
    StoreModule.forFeature('northstar', reducers, { metaReducers }),
    EffectsModule.forFeature([
      DevServersEffects,
      FeaturesEffects,
      SettingsEffects,
      RouterEffects
    ])
  ],
  exports: [CardContainerComponent, CardComponent]
})
export class NorthstarModule {
  static forRoot(params: ForRootParams): ModuleWithProviders<NorthstarModule> {
    return {
      ngModule: NorthstarModule,
      providers: [
        AuthService,
        CardService,
        CardContainerService,
        WebsocketGatewayService,
        LogService,
        HMRService,
        NavigationService,
        RoutingService,
        CompilationService,
        PolarisService,
        LoaderService,
        SettingsService,
        { provide: ROUTES, useValue: params.devModules, multi: true },
        { provide: DEV_MODULES, useValue: params.devModules },
        { provide: OIDC_SETTINGS, useValue: params.oidcSettings },
        { provide: APP_NAME, useValue: params.appName },
        { provide: APP_VERSION, useValue: params.appVersion },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: ENVIRONMENT_CONFIG, useValue: params.envConfig },
        { provide: LOG_SETTINGS, useValue: params.logSettings }
      ]
    };
  }
}
