import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { State } from '../state/reducer';

import { CardType, getFeaturesState, SettingsService } from '@ipreo/northstar';

@Injectable()
export class DashboardService {
  private features$ = this.store.pipe(
    select((state) => getFeaturesState(state).cards)
  );
  private settings$ = this.settingsService
    .get('cards')
    .pipe(map((state) => (state.setting as { identifier: string }[]) || []));

  public cards$ = combineLatest([this.features$, this.settings$]).pipe(
    map(([featureCards, settingsCards]) => {
      const result: CardType[] = [];
      if (
        settingsCards &&
        settingsCards.length &&
        featureCards &&
        featureCards.length
      ) {
        // adding items in proper order that exist in settings
        for (const settingCard of settingsCards) {
          const cardType = featureCards.find(
            (card: CardType) => card.identifier === settingCard.identifier
          );
          if (cardType) {
            result.push(cardType);
          }
        }
      }

      // adding rest of items from features to the end of result array (even if they not in settings)
      // avoiding duplicates
      if (featureCards && featureCards.length) {
        for (const featureCard of featureCards) {
          const cardType = result.find(
            (card: CardType) => card === featureCard
          );
          if (!cardType) {
            result.push(featureCard);
          }
        }
      }

      return result;
    })
  );

  constructor(
    private store: Store<State>,
    private settingsService: SettingsService
  ) {}

  public updatePositions(positions: { id: string }[]) {
    this.settingsService.set(
      'cards',
      positions.map((position) => ({ identifier: position.id }))
    );
  }
}
