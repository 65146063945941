import {
  Injectable,
  ViewContainerRef,
  Compiler,
  Injector,
  ComponentRef,
  ModuleWithComponentFactories,
  Type,
} from '@angular/core';
import { NMP } from '@ipreo/nmp-types';
import { ModuleFactories, ModuleFactoryManifest } from '../types';

/* eslint-disable @typescript-eslint/no-explicit-any */

@Injectable()
export class CompilationService {
  constructor(private compiler: Compiler) {}

  public baseCompileModuleAsync(m: Type<unknown>) {
    return this.compiler.compileModuleAsync(m);
  }

  public compileModule(
    response: NMP.LoaderResponse
  ): Promise<ModuleFactoryManifest> {
    return new Promise((resolve, reject) => {
      try {
        const angularManifest = this.getDefaultAngularManifest(
          response.manifest
        );
        const module = this.extractNgModule(
          response.module,
          response.manifest.jsBundle,
          angularManifest
        );
        this.compiler.compileModuleAsync(module).then((moduleFactory) => {
          resolve({
            moduleFactory: moduleFactory,
            manifest: response.manifest,
          } as ModuleFactoryManifest);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  public compileModules(
    responses: NMP.LoaderResponse[]
  ): Promise<ModuleFactoryManifest[]> {
    return new Promise((resolve, reject) => {
      try {
        resolve(
          responses.map((response) => {
            const angularManifest = this.getDefaultAngularManifest(
              response.manifest
            );
            const module = this.extractNgModule(
              response.module,
              response.manifest.jsBundle,
              angularManifest
            );
            return {
              moduleFactory: this.compiler.compileModuleSync(module),
              manifest: response.manifest,
            } as ModuleFactoryManifest;
          })
        );
      } catch (err) {
        reject(err);
      }
    });
  }

  public compileAngularComponent(
    response: NMP.LoaderResponse,
    manifestLoadHandler?: (
      manifest: NMP.ModuleManifest
    ) => NMP.AngularModuleManifest
  ): Promise<ModuleFactories> {
    const angularManifest = this.fetchAngularManifest(
      response.manifest,
      manifestLoadHandler
    );
    const identifier = `${response.manifest.name}@${response.manifest.version}`;

    return this.compileModuleAndComponent(
      response.module,
      identifier,
      angularManifest
    );
  }

  public compileAngularComponents(
    responses: NMP.LoaderResponse[],
    manifestLoadHandler?: (
      manifest: NMP.ModuleManifest
    ) => NMP.AngularModuleManifest,
    errorHandler?: (
      err: unknown,
      module: NMP.ModuleManifest
    ) => ModuleFactories | Promise<ModuleFactories>
  ): Promise<ModuleFactories[]> {
    const instantiatedFactories = {};

    const finalResults = responses.map((response) => {
      const identifier = `${response.manifest.name}@${response.manifest.version}`;

      if ((response as any).err) {
        instantiatedFactories[identifier] = Promise.resolve(
          errorHandler((response as any).err, response.manifest)
        );
      }

      if (
        !Object.prototype.hasOwnProperty.call(instantiatedFactories, identifier)
      ) {
        const angularManifest = this.fetchAngularManifest(
          response.manifest,
          manifestLoadHandler
        );
        const factories = this.compileModuleAndComponent(
          response.module,
          identifier,
          angularManifest
        );

        instantiatedFactories[identifier] = factories;
        return factories;
      } else {
        return instantiatedFactories[identifier];
      }
    });

    return Promise.all(finalResults);
  }

  public compileAndCreateAngularComponent(
    response: NMP.LoaderResponse,
    viewref: ViewContainerRef,
    injector: Injector | null,
    manifestLoadHandler?: (
      manifest: NMP.ModuleManifest
    ) => NMP.AngularModuleManifest
  ): Promise<ComponentRef<any>> {
    const angularManifest = this.fetchAngularManifest(
      response.manifest,
      manifestLoadHandler
    );

    const identifier = `${response.manifest.name}@${response.manifest.version}`;

    return this.compileAndInstantiateModuleAndComponent(
      viewref,
      response.module,
      identifier,
      angularManifest,
      injector
    );
  }

  public compileModuleAndComponent(
    module: any,
    identifier: string,
    angularManifest: NMP.AngularModuleManifest
  ): Promise<ModuleFactories> {
    return new Promise((resolve: any, reject: any) => {
      let ngModule: any;

      try {
        ngModule = this.extractNgModule(module, identifier, angularManifest);
      } catch (err) {
        reject(err);
      }

      return this.compileModuleAndFindComponent(
        ngModule,
        angularManifest.selector
      )
        .then((factories: ModuleFactories) => resolve(factories))
        .catch((err) => reject(err));
    });
  }

  public instantiateComponent(
    viewref: ViewContainerRef,
    factories: ModuleFactories,
    injector: Injector | null
  ): Promise<ComponentRef<any>> {
    return new Promise((resolve) => {
      const modRef = factories.moduleFactory.create(
        injector || viewref.injector
      );
      resolve(
        viewref.createComponent(
          factories.componentFactory,
          viewref.length,
          modRef.injector,
          [],
          modRef
        )
      );
    });
  }

  public compileAndInstantiateModuleAndComponent(
    viewref: ViewContainerRef,
    module: any,
    identifier: string,
    angularManifest: NMP.AngularModuleManifest,
    injector: Injector | null
  ): Promise<ComponentRef<any>> {
    return new Promise((resolve: any, reject: any) => {
      let ngModule: any;

      try {
        ngModule = this.extractNgModule(module, identifier, angularManifest);
      } catch (err) {
        reject(err);
      }

      return this.compileAndInstantiate(
        viewref,
        ngModule,
        angularManifest.selector,
        injector
      )
        .then((componentRef: ComponentRef<any>) => resolve(componentRef))
        .catch((err) => reject(err));
    });
  }

  public compileAndInstantiate(
    viewref: ViewContainerRef,
    modType: any,
    selector: string,
    injector: Injector | null
  ): Promise<ComponentRef<any>> {
    return this.compileModuleAndFindComponent(
      modType,
      selector
    ).then((factories: ModuleFactories) =>
      this.instantiateComponent(viewref, factories, injector)
    );
  }

  public compileModuleAndFindComponent(
    modType: any,
    selector: string
  ): Promise<ModuleFactories> {
    return this.compiler
      .compileModuleAndAllComponentsAsync<any>(modType)
      .then((factory: ModuleWithComponentFactories<any>) => {
        let cmpFactory: any;
        // now look for the module's main component so we can instantiate it
        for (let i = factory.componentFactories.length - 1; i >= 0; i--) {
          if (factory.componentFactories[i].selector === selector) {
            cmpFactory = factory.componentFactories[i];
          }
        }

        if (!cmpFactory) {
          throw new Error('Component not found');
        }

        return {
          moduleFactory: factory.ngModuleFactory,
          componentFactory: cmpFactory,
        } as ModuleFactories;
      });
  }

  private fetchAngularManifest(
    manifest: NMP.ModuleManifest,
    manifestLoadHandler?: (
      manifest: NMP.ModuleManifest
    ) => NMP.AngularModuleManifest
  ): NMP.AngularModuleManifest {
    return manifestLoadHandler
      ? manifestLoadHandler(manifest)
      : this.getDefaultAngularManifest(manifest);
  }

  private getDefaultAngularManifest(
    moduleManifest: NMP.ModuleManifest
  ): NMP.AngularModuleManifest {
    const platforms: any = moduleManifest.platforms;
    return {
      moduleName: platforms.angular.moduleName,
      selector: platforms.angular.selector,
    } as NMP.AngularModuleManifest;
  }

  private extractNgModule(
    module: any,
    jsBundle: string,
    manifest: NMP.AngularModuleManifest
  ) {
    if (!manifest || !manifest.moduleName) {
      throw new Error(
        `Cannot Load ${jsBundle} module. Manifest section missing platforms.angular section. Name of Angular Module to load is unknown.`
      );
    }

    return module[manifest.moduleName];
  }
}
