import { Action } from '@ngrx/store';
import { HomeFeaturesResponse } from './state';

export const FEATURES_FETCH = '[northstar] Fetch Features';
export const FEATURES_FETCH_SUCCESS = '[northstar] Fetch Features Success';
export const FEATURES_FETCH_ERROR = '[northstar] Fetch Features Error';
export const FEATURES_NEW_ROUTE = '[northstar] New Features Route';
export const FEATURES_RESET_ROUTE = '[northstar] Reset Features Route';

/* tslint:disable:max-classes-per-file */

export class FeaturesFetchAction implements Action {
  public readonly type = FEATURES_FETCH;
}

export class FeaturesFetchSuccessAction implements Action {
  public readonly type = FEATURES_FETCH_SUCCESS;

  constructor(public payload: HomeFeaturesResponse) {}
}

export class FeaturesFetchErrorAction implements Action {
  public readonly type = FEATURES_FETCH_ERROR;

  constructor(public payload: string) {}
}

// Navigation actions for polaris services
export class FeaturesNewRouteAction implements Action {
  public readonly type = FEATURES_NEW_ROUTE;

  constructor(public payload: HomeFeaturesResponse) {}
}

export class FeaturesResetRouteAction implements Action {
  public readonly type = FEATURES_RESET_ROUTE;

  constructor(public applicationName: string) {}
}

export type Actions =
  | FeaturesFetchAction
  | FeaturesFetchSuccessAction
  | FeaturesFetchErrorAction
  | FeaturesNewRouteAction
  | FeaturesResetRouteAction;
