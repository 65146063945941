import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, distinctUntilChanged, takeWhile } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { get, isEqual } from 'lodash';
import { getSettingsState } from '../state/reducer';
import { SettingsSaveAction } from '../state/settings/actions';

export interface SettingsSlice {
  setting: string | Record<string, unknown> | Array<unknown>;
  loading: boolean;
  loaded: boolean;
  error?: string;
}

export interface SettingsSaveResult {
  requestId: string;
  loading: boolean;
  loaded: boolean;
}

@Injectable()
export class SettingsService {
  constructor(private store: Store<unknown>) {}

  public get(path: string): Observable<SettingsSlice> {
    return this.store.pipe(
      select(getSettingsState),
      map(({ settings, loading, loaded, error }) => ({
        setting: get(settings, path),
        loading,
        loaded,
        error,
      })),
      distinctUntilChanged(isEqual)
    );
  }

  public set(path: string, setting: unknown): Observable<SettingsSaveResult> {
    const requestId = uuidv4();

    this.store.dispatch(
      new SettingsSaveAction({
        requestId,
        path,
        setting,
      })
    );

    return this.store.pipe(
      select(getSettingsState),
      map(({ requests }) => {
        const requestContext =
          requests[requestId] ||
          ({
            loading: true,
            loaded: false,
          } as SettingsSlice);

        if (requestContext.error) {
          throw new Error(requestContext.error);
        }
        return {
          ...requestContext,
          requestId,
        };
      }),
      distinctUntilChanged(isEqual),
      takeWhile(({ loaded }) => !loaded, true)
    );
  }
}
