<span
  (clickOutside)="toggleDropdown()"
  [clickOutsideEnabled]="isDropdownVisible"
  class="c-dropdown"
>
  <a
  (click)="toggleDropdown()"
    href="javascript:void(0);"
    class="c-header-item-link"
  >
    <span
      class="c-avatar c-avatar-white"   
      [attr.data-text]="getUserInitials()"
      data-status="success"
    >
    </span>
  </a>

  <div
    *ngIf="isDropdownVisible"
    class="c-dropdown-list c-dropdown-list-below c-dropdown-list-right c-d-block"
  >
    <div class="c-media c-media-middle">
      <span
        class="c-avatar c-avatar-primary"
        [attr.data-text]="getUserInitials()"
      ></span>
      <div class="c-media-body">
        <div class="c-text-md c-text-bold">{{ params.name }}</div>
        <div class="c-text-sm c-text-muted">{{ params.email }}</div>
      </div>
    </div>
    <div class="c-dropdown-divider"></div>
    <ul
      class="c-dropdown-list-below"
    >
      <li *ngFor="let itemParams of params.menuItems" class="c-dropdown">
        <a
          *ngIf="itemParams.hasOwnProperty('event')"
          href="javascript:void(0);"
          (click)="itemParams.event($event)"
          [ngClass]="(itemParams.classes || []).join(' ')"
          [attr.data-automation-e2e-selector]="
            'nav-item-' +
            (itemParams.hasOwnProperty('name') ? itemParams.name : '')
          "
        >
          <ng-container *ngIf="params.hasOwnProperty('name')">
            <span
              *ngIf="params.icon"
              [ngClass]="'fa fa-' + params.icon"
              aria-hidden="true"
              [attr.data-automation-e2e-selector]="
                'nav-item-icon-' + params.name
              "
            ></span>
            <span
              [attr.data-automation-e2e-selector]="
                'nav-item-title-' + params.name
              "
              >{{ itemParams.name }}</span
            >
          </ng-container>
          <ng-container *ngIf="params.hasOwnProperty('innerHtml')">
            <span [innerHtml]="params.innerHtml"></span>
          </ng-container>
        </a>
      </li>
    </ul>
  </div>
</span>
