<header class="c-header">
  <div class="c-header-left">
    <polaris-header-logo></polaris-header-logo>
  </div>

  <div class="c-header-right">
    <ng-container *ngFor="let item of headerRight">
      <polaris-header-info
        *ngIf="item.type === 'info'"
        [params]="item.params"
      ></polaris-header-info>

      <polaris-header-dropdown
        *ngIf="item.type === 'dropdown'"
        [params]="item.params"
        dropdownAlign="right"
      >
      </polaris-header-dropdown>

      <polaris-header-link
      *ngIf="item.type === 'link'"
      [params]="item.params"
      ></polaris-header-link>
      
      <polaris-user-menu
        *ngIf="item.type === 'user'"
        [params]="item.params"
      ></polaris-user-menu>
    </ng-container>
  </div>
</header>

<polaris-header-tabs [headerItems]="headerCenter"></polaris-header-tabs>
