import { Inject, Injectable } from '@angular/core';
import { User } from 'oidc-client';
import { take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { EnvironmentConfig } from '../../types';
import { ENVIRONMENT_CONFIG } from '../../tokens';
import { LogMessage } from './log-message';
import { LOG_SETTINGS, LogSettings } from './log-settings';
import { AuthService } from '../auth.service';
import { APP_NAME, APP_VERSION } from '../../tokens';

// const circularJson = require('circular-json');

@Injectable()
export class LogService {
  private _errors: LogMessage[];
  private _events: LogMessage[];

  constructor(
    private http: HttpClient,
    @Inject(LOG_SETTINGS) private readonly settings: LogSettings,
    @Inject(ENVIRONMENT_CONFIG) private readonly apiConfig: EnvironmentConfig,
    private authService: AuthService,
    @Inject(APP_NAME) private moduleName: string,
    @Inject(APP_VERSION) private moduleVersion: string
  ) {
    this._errors = [];
    this._events = [];
  }

  public start() {
    const send = () => {
      this.flushErrors();
      this.flushEvents();
    };

    setInterval(send, this.settings.interval);
    window.addEventListener('beforeunload', send);
  }

  public flushErrors() {
    this.send(this._errors, 'errors');
  }

  public flushEvents() {
    this.send(this._events, 'events');
  }

  private copy(messages: LogMessage[]): LogMessage[] {
    const copy = messages.slice();
    messages.splice(0, messages.length);

    return copy;
  }

  private send(messages: LogMessage[], endpoint: string) {
    if (messages.length) {
      const payload = this.copy(messages);
      const url = `${this.apiConfig.northstar.host.api}/api/log/${endpoint}`;
      const post = this.http.post(url, payload);

      post.subscribe({
        error: () => {
          const current = this.copy(messages);
          messages.push(...payload, ...current);
        },
      });
    }
  }

  private log(
    name: string,
    message: string | Record<string, unknown>,
    messages: LogMessage[]
  ): void {
    if (this.authService.currentUser) {
      this.logWithUser(name, message, messages, this.authService.currentUser);
    } else {
      this.authService.userLoadedEvent
        .asObservable()
        .pipe(take(1))
        .subscribe((user) => {
          this.logWithUser(name, message, messages, user);
        });
    }
  }

  private logWithUser(
    name: string,
    message: string | Record<string, unknown>,
    messages: LogMessage[],
    user: User
  ) {
    messages.push({
      userId: user.profile.sub,
      userName: user.profile.name,
      userEmail: user.profile.email,
      platform: navigator.platform,
      userAgent: navigator.userAgent,
      timestamp: Date.now().toString(),
      eventName: name,
      moduleName: this.moduleName,
      moduleVersion: this.moduleVersion,
      eventData: message,
    });
  }

  public event(name: string, message: string | Record<string, unknown>): void {
    this.log(name, message, this._events);
  }

  public error(name: string, message: string | Record<string, unknown>): void {
    this.log(name, message, this._errors);
  }
}
