import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  APP_INITIALIZER,
  CompilerFactory,
  COMPILER_OPTIONS,
  Compiler,
  ApplicationRef,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { JitCompilerFactory } from '@angular/platform-browser-dynamic';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import {
  StoreModule,
  Store,
  ActionReducer,
  MetaReducer,
  Action,
} from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { NxModule } from '@nrwl/nx';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  removeNgStyles,
  createInputTransfer,
  createNewHosts,
} from '@angularclass/hmr';
import {
  NorthstarModule,
  AuthService,
  WebsocketGatewayService,
  LogService,
  WebsocketGatewayAuthType,
  FeaturesFetchAction,
  SettingsFetchAction,
  ENVIRONMENT_CONFIG,
} from '@ipreo/northstar';
import { NorthstarAlertsModule } from '@ipreo/northstar-alerts';
import { take } from 'rxjs/operators';
import { ClickOutsideModule, FooterModule } from '@ipreo/ngx-sprinkles';

import { environment } from './../environments/environment';

import { routes } from './app.routes';
import { devModules } from './app.dev-modules';
import { reducers, State } from './state/reducer';
import { AppComponent } from './app.component';
import { DashboardService } from './dashboard/dashboard.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NoContentComponent } from './no-content/no-content.component';
import { HeaderComponent } from './shared/header/header.component';
import { HeaderLinkComponent } from './shared/header/components/header-link.component';
import { HeaderDropdownComponent } from './shared/header/components/header-dropdown.component';
import { HeaderInfoComponent } from './shared/header/components/header-info.component';
import './app.system-bootstrap';
import { HeaderLogoComponent } from './shared/header/components/header-logo/header-logo.component';
import { UserMenuComponent } from './shared/header/components/user-menu/user-menu.component';
import { HeaderTabsComponent } from './shared/header/components/header-tabs/header-tabs.component';

if (!Object.prototype.hasOwnProperty.call(window, 'PolarisFirstLoad')) {
  window['PolarisFirstLoad'] = true;
}

const CUPCAKE_MAJOR_VERSION: number = parseInt(
  window.sessionStorage.getItem('cupcake-version'),
  10
);

async function loadUserInfo(
  store: Store<State>,
  websocketGatewayService: WebsocketGatewayService,
  auth: AuthService
): Promise<boolean> {
  await nmp.getLoader().authenticate(auth.currentUser.access_token);

  store.dispatch(new FeaturesFetchAction());
  store.dispatch(new SettingsFetchAction());

  websocketGatewayService.error$.subscribe(
    (error) => {
      console.warn(error);
    },
    (error) => {
      console.error('errors$ error', error);
    }
  );

  websocketGatewayService.starting$.subscribe(
    () => {
      console.log('Websocket Gateway connection has been started');
    },
    (error) => {
      console.warn('Websocket Gateway connection failed to start!', error);
    }
  );

  websocketGatewayService.closing$.subscribe(
    () => {
      console.log('Websocket Gateway connection has been closed');
    },
    (error) => {
      console.warn('Websocket Gateway connection failed to be close!', error);
    }
  );

  websocketGatewayService.connect(
    WebsocketGatewayAuthType.JWT,
    auth.currentUser.access_token
  );

  auth.userLoadedEvent.subscribe((user) => {
    websocketGatewayService.connect(
      WebsocketGatewayAuthType.JWT,
      user.access_token
    );
  });

  const pendo = window['pendo'];
  if (pendo) {
    pendo.initialize({
      visitor: {
        id: auth.currentUser.profile.sub,
        email: auth.currentUser.profile.email,
        full_name: auth.currentUser.profile.name,
      },
    });
  }

  window['PolarisFirstLoad'] = false;

  return true;
}

const startingPath = window.location.pathname + window.location.search;

const appInitializer = {
  provide: APP_INITIALIZER,
  useFactory: (
    store: Store<State>,
    auth: AuthService,
    websocketGatewayService: WebsocketGatewayService,
    logService: LogService
  ) => () => {
    if (window['PolarisFirstLoad']) {
      logService.start();
      setClientEnv();
      return auth.getUser().then((loggedIn) => {
        if (loggedIn) {
          return loadUserInfo(store, websocketGatewayService, auth);
        } else {
          return auth
            .endSigninMainWindow(
              startingPath.includes('?') ? startingPath : undefined
            )
            .then((state) => {
              if (auth.loggedIn) {
                history.pushState(
                  '',
                  document.title,
                  state['startUrl'] === undefined
                    ? startingPath
                    : state['startUrl']
                );
                return loadUserInfo(store, websocketGatewayService, auth);
              } else {
                console.log('STARTING PATH: ', startingPath);

                auth.startSigninMainWindow({
                  startUrl: startingPath,
                });
                return new Promise((resolve) => {
                  setTimeout(() => resolve(null), 5000);
                });
              }
            });
        }
      });
    }
    return true;
  },
  deps: [Store, AuthService, WebsocketGatewayService, LogService],
  multi: true,
};

export function setClientEnv() {
  const clientEnv = window.sessionStorage.getItem('client_env');

  if (!clientEnv) {
    const searchParams: URLSearchParams = new URL(window.location.href).searchParams;
    //accessing clienEnv from dev server url
    const devServer = searchParams.get('devServer');
    if (devServer) {
      const devServerUrl = new URL(devServer);
      if (devServerUrl &&
        (devServerUrl.hostname === 'packages.dev.muni.globalmarkets.ihsmarkit.com' ||
          devServerUrl.hostname === 'local.muni.ipreo.com')) {
        const clientEnvJson = devServerUrl.pathname.split('/').slice(-1)[0];
        const clientEnv = clientEnvJson.split('-')[1].split('.')[0];
        window.sessionStorage.setItem('client_env', clientEnv);
        return;
      }
    }

    //accessing clienEnv from query string params
    let clientEnv: string = null;
    searchParams.forEach((value, key) => {
      if (key.toLowerCase() === 'clientenv') { //case-insensitive comparison of searchparams
        clientEnv = value;
      }
    });
    if (!clientEnv && environment.env === 'dev') {
      clientEnv = 'dev'; // required for muni apps to work in dev env
    }
    if (clientEnv) {
      window.sessionStorage.setItem('client_env', clientEnv);
      return;
    }
  }
}

export function envFactory() {
  return {
    northstar: {
      host: {
        api: environment.apiBaseUrl,
        //hub: environment.hubUrl, //TODO: To be removed in future, as legacy WS is deprecated and IHSM push is used instead
        ws: environment.wsUrl,
        registry: environment.registryUrl,
      },
      env: environment.env,
      cupcakeMajorVersion: CUPCAKE_MAJOR_VERSION,
    },
  };
}

export function createCompiler(compilerFactory: CompilerFactory) {
  return compilerFactory.createCompiler();
}
/* eslint-disable @typescript-eslint/no-explicit-any */
export function stateSetter(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state: any, action: any) {
    if (action.type === 'SET_ROOT_STATE') {
      return action.payload;
    }
    return reducer(state, action);
  };
}
/* eslint-enable @typescript-eslint/no-explicit-any */
export const stateReloader: MetaReducer<State, Action>[] = [stateSetter];

interface InternalStateType {
  [key: string]: unknown;
}

interface StoreType {
  state: InternalStateType;
  rootState: InternalStateType;
  restoreInputValues: () => void;
  disposeOldHosts: () => void;
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    NoContentComponent,
    HeaderComponent,
    HeaderLinkComponent,
    HeaderDropdownComponent,
    HeaderInfoComponent,
    HeaderLogoComponent,
    UserMenuComponent,
    HeaderTabsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NxModule.forRoot(),
    RouterModule.forRoot(routes, {
      enableTracing: environment.enableRouterTracing,
      initialNavigation: 'disabled',
      relativeLinkResolution: 'legacy',
    }),
    StoreModule.forRoot(reducers, { metaReducers: stateReloader }),
    ClickOutsideModule,
    environment.instrumentStore
      ? StoreDevtoolsModule.instrument({ maxAge: 50 })
      : [],
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
    }),
    NorthstarModule.forRoot({
      appName: 'polaris-home',
      appVersion: '0.1.0',
      oidcSettings: environment.oidcSettings,
      logSettings: {
        interval: 10000,
      },
      devModules: environment.loadLocalDevModules ? devModules : [],
    }),
    NorthstarAlertsModule,
    FooterModule
  ],
  providers: [
    appInitializer,
    DashboardService,
    { provide: COMPILER_OPTIONS, useValue: [{ useJit: true }] },
    {
      provide: CompilerFactory,
      useClass: JitCompilerFactory,
      deps: [COMPILER_OPTIONS],
    },
    { provide: Compiler, useFactory: createCompiler, deps: [CompilerFactory] },
    { provide: ENVIRONMENT_CONFIG, useFactory: envFactory },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    public appRef: ApplicationRef,
    private appStore: Store<Record<string, unknown>>
  ) { }

  hmrOnInit(store: StoreType) {
    if (!store || !store.rootState) {
      return;
    }
    if (store.rootState) {
      // Important! Don't HMR the router state since we need to load the dynamic routes before nav starts.
      delete store.rootState.router;

      this.appStore.dispatch({
        type: 'SET_ROOT_STATE',
        payload: store.rootState,
      });
    }
    // set input values
    if ('restoreInputValues' in store) {
      const restoreInputValues = store.restoreInputValues;
      setTimeout(restoreInputValues);
    }

    this.appRef.tick();
    Object.keys(store).forEach((prop) => delete store[prop]);
  }
  hmrOnDestroy(store: StoreType) {
    const cmpLocation = this.appRef.components.map(
      (cmp) => cmp.location.nativeElement
    );
    // save state
    this.appStore.pipe(take(1)).subscribe((s) => (store.rootState = s));
    // recreate root elements
    store.disposeOldHosts = createNewHosts(cmpLocation);
    // save input values
    store.restoreInputValues = createInputTransfer();
    // remove styles
    removeNgStyles();
  }
  hmrAfterDestroy(store: StoreType) {
    // display new elements
    store.disposeOldHosts();
    delete store.disposeOldHosts;
  }
}
