import { ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from './localStorageSync';

import { DevServersState } from './dev-servers/state';
import { FeaturesState } from './features/state';
import { SettingsState } from './settings/state';

import { devServersReducer } from './dev-servers/reducer';
import { featuresReducer } from './features/reducer';
import { settingsReducer } from './settings/reducer';

export interface State {
  devServers: DevServersState;
  features: FeaturesState;
  settings: SettingsState;
}

export const reducers = {
  devServers: devServersReducer,
  features: featuresReducer,
  settings: settingsReducer,
};

export function localStorageSyncReducer(
  reducer: ActionReducer<unknown>
): ActionReducer<unknown> {
  return localStorageSync({
    keys: ['devServers'],
    rehydrate: true,
  })(reducer);
}

export const metaReducers: MetaReducer<unknown>[] = [localStorageSyncReducer];

const northstarState = <T>(selector: (state: State) => T) => {
  return (state) =>
    state && state['northstar'] ? selector(state['northstar'] as State) : null;
};

export const getDevServersState = northstarState(
  (state: State) => state.devServers
);
export const getFeaturesState = northstarState(
  (state: State) => state.features
);
export const getSettingsState = northstarState(
  (state: State) => state.settings
);
