<div class="header c-card-header">
  <div class="c-card-header-title">All Notifications</div>
  <div class="c-card-header-menu">
    <div class="c-card-header-menu-item">
      <a (click)="showSettings()" class="settings-link c-text-gray-4">
        <span class="fa fa-gear"></span>
      </a>
    </div>
  </div>
</div>
<div class="subheader c-p-md c-p-top-sm c-p-bottom-sm">
  <a
    *ngIf="filteredToUnread$ | async"
    (click)="viewAll()"
    class="c-a c-text-sm filter-btn"
    >View All</a
  >
  <a
    *ngIf="(filteredToUnread$ | async) === false"
    (click)="filterToUnread()"
    class="c-a c-text-sm filter-btn"
    >Filter by Unread</a
  >
  <a
    *ngIf="(messagesStatus$ | async).unreadCount > 0"
    (click)="markAllAsRead()"
    class="c-a c-text-sm read-all"
    >Mark All As Read</a
  >
</div>
<div class="body">
  <div
    *ngIf="(messagesState$ | async).loaded && (messages$ | async).length === 0"
    class="messages"
    [@fadeInOut]
  >
    <div class="c-p-xl c-p-bottom-lg bell c-text-gray-4">
      <span class="fa fa-bell"></span>
    </div>
    <ng-container *ngIf="filteredToUnread$ | async">
      <div
        class="c-text-gray-9"
        [ngClass]="{
          'c-header-xs': cupcakeMajorVersion === 1,
          'c-header-md': cupcakeMajorVersion > 1
        }"
      >
        No Unread Notifications
      </div>
      <div class="c-text-md c-text-gray-6">
        Click
        <a class="c-text-gray-6 text-link" (click)="viewAll()">View All</a>
        to view all of your notifications
      </div>
    </ng-container>
    <ng-container *ngIf="(filteredToUnread$ | async) === false">
      <div
        class="c-text-gray-9"
        [ngClass]="{
          'c-header-xs': cupcakeMajorVersion === 1,
          'c-header-md': cupcakeMajorVersion > 1
        }"
      >
        No Notifications
      </div>
    </ng-container>
  </div>
  <div
    *ngIf="!(messagesState$ | async).loaded && (messagesState$ | async).loading"
    class="messages"
    [@fadeInOut]
  >
    <div class="c-skeleton-loading"></div>
    <hr class="c-hr" />
    <div class="c-skeleton-loading"></div>
    <hr class="c-hr" />
    <div class="c-skeleton-loading"></div>
    <hr class="c-hr" />
  </div>
  <div *ngIf="(messagesState$ | async).loaded" class="messages" [@fadeInOut]>
    <a
      *ngFor="let message of messages$ | async; trackBy: trackById"
      class="message"
      [ngClass]="{
        'c-bg-primary-0': !message.isRead,
        'message-read': message.isRead
      }"
      (click)="click(message)"
      [@fadeSlideInOut]
    >
      <div class="gutter">
        <div
          class="read-button"
          [ngClass]="{ 'is-read': message.isRead }"
          (click)="toggleRead(message, $event)"
          [sprTooltip]="message.isRead ? 'Mark unread' : 'Mark read'"
          [sprTooltipOptions]="{
            placement: 'right'
          }"
        >
          <span
            class="fa fa-circle"
            [ngClass]="{
              'fa-circle': !message.isRead,
              'fa-circle-o': message.isRead,
              'c-text-primary-7': !message.isRead
            }"
          ></span>
        </div>
      </div>
      <div class="message-wrap c-p-sm c-p-left-0">
        <div
          class="message-body c-text-gray-9 c-text-sm"
          [textContent]="message.body"
        ></div>
        <div class="byline c-text-gray-6 c-text-xs">
          <span *ngIf="message.senderName" class="sender"
            >{{ message.senderName }}&nbsp;&bull;&nbsp;</span
          >
          <span class="time">{{
            message.sentUtcDateTime | date: 'd MMM yyyy h:mm aa'
          }}</span>
        </div>
      </div>
    </a>
    <div
      *ngIf="
        (messages$ | async).length <
        ((filteredToUnread$ | async)
          ? (messagesStatus$ | async).unreadCount
          : (messagesStatus$ | async).totalCount)
      "
      class="load-more c-p-md"
    >
      <button
        (click)="loadMore()"
        type="button"
        class="c-btn c-btn-secondary c-btn-sm c-btn-block"
      >
        <span *ngIf="!(messagesState$ | async).loading"
          >Load More Messages</span
        >
        <span
          *ngIf="(messagesState$ | async).loading"
          class="fa fa-spin fa-circle-o-notch c-text-gray-6"
        ></span>
      </button>
    </div>
  </div>
</div>
<northstar-alerts-settings
  *ngIf="settingsOpen"
  [hide]="hideSettings"
  [@slideInOut]
></northstar-alerts-settings>
