import {
  OnDestroy,
  OnInit,
  Component,
  Input,
  ChangeDetectorRef,
  Inject,
} from '@angular/core';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { ENVIRONMENT_CONFIG, EnvironmentConfig } from '@ipreo/northstar';
import { DesktopNotificationService } from '../../services/desktop-notifications.service';
import {
  getMessageTypesStoreState,
  getPreferencesStoreState,
  getTransportsStoreState,
  getConfigStoreState,
  State,
} from '../../state/reducer';
import { MessageTypesState } from '../../state/message-types/state';
import { ConfigState } from '../../state/config/state';
import { MessageTypesLoadAction } from '../../state/message-types/actions';
import {
  PreferencesLoadAction,
  PreferencesUpdateAction,
} from '../../state/preferences/actions';
import { TransportsLoadAction } from '../../state/transports/actions';
import { MessageType, MessageTypePreference, Transport } from '../../types';

@Component({
  selector: 'northstar-alerts-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnDestroy, OnInit {
  @Input()
  public hide: () => void;

  public notificationsEnabled: boolean;

  public cupcakeMajorVersion: number;

  public messageTypeStates$: Observable<MessageTypesState> = this.store.pipe(
    select(getMessageTypesStoreState)
  );

  public messageTypes$: Observable<MessageType[]> = combineLatest([
    this.messageTypeStates$,
    this.store.pipe(select(getConfigStoreState)),
  ]).pipe(
    map(([messageTypesState, configState]) => {
      return messageTypesState.messageTypes.filter(
        (messageType) => messageType.appCode === configState.appCode
      );
    })
  );

  public messageTypePreferences$: Observable<
    {
      messageType: MessageType;
      preference: MessageTypePreference;
      transport: Transport;
    }[]
  > = combineLatest([
    this.store.pipe(select(getPreferencesStoreState)),
    this.store.pipe(select(getTransportsStoreState)),
    this.messageTypes$,
  ]).pipe(
    map(([preferences, transportsState, messageTypes]) => {
      const transport = transportsState.transports.find((t) => t.defaultRoute);
      return messageTypes.map((messageType) => {
        return {
          messageType,
          transport,
          preference: preferences.preferences.find(
            (preference) => preference.messageTypeId === messageType.id
          ),
        };
      });
    })
  );

  public config$: Observable<ConfigState> = this.store.pipe(
    select(getConfigStoreState)
  );

  private notificationSubscription: Subscription;

  constructor(
    public notificationService: DesktopNotificationService,
    private store: Store<State>,
    private ref: ChangeDetectorRef,
    @Inject(ENVIRONMENT_CONFIG) config: EnvironmentConfig
  ) {
    this.notificationsEnabled = notificationService.enabled;
    this.cupcakeMajorVersion = config.northstar.cupcakeMajorVersion;
  }

  ngOnInit() {
    this.notificationSubscription = this.notificationService.change.subscribe(
      () => {
        setTimeout(() => {
          this.notificationsEnabled = this.notificationService.enabled;
          this.ref.detectChanges();
        }, 10);
      }
    );

    this.store.dispatch(new PreferencesLoadAction());
    this.store.dispatch(new MessageTypesLoadAction());
    this.store.dispatch(new TransportsLoadAction());
  }

  ngOnDestroy(): void {
    if (this.notificationSubscription) {
      this.notificationSubscription.unsubscribe();
    }
  }

  public preferenceChange(
    messageTypePreference: {
      messageType: MessageType;
      preference: MessageTypePreference;
      transport: Transport;
    },
    event: MouseEvent
  ) {
    event.preventDefault();

    this.store.dispatch(
      new PreferencesUpdateAction({
        messageTypeId: messageTypePreference.messageType.id,
        transportTypeId: messageTypePreference.transport.id,
        active: event.target['checked'],
      })
    );
  }

  public notificationChange(event: MouseEvent) {
    event.preventDefault();

    this.notificationService.toggle();
  }
}
