import { createFeatureSelector, createSelector } from '@ngrx/store';

import { MessageTypesState } from './message-types/state';
import { MessagesState } from './messages/state';
import { MessagesStatusState } from './messages-status/state';
import { ConfigState } from './config/state';
import { PreferencesState } from './preferences/state';
import { TransportsState } from './transports/state';

import { messageTypesReducer } from './message-types/reducer';
import { messagesReducer } from './messages/reducer';
import { messagesStatusReducer } from './messages-status/reducer';
import { configReducer } from './config/reducer';
import { preferencesReducer } from './preferences/reducer';
import { transportsReducer } from './transports/reducer';

export interface State {
  messageTypes: MessageTypesState;
  messages: MessagesState;
  status: MessagesStatusState;
  config: ConfigState;
  preferences: PreferencesState;
  transports: TransportsState;
}

export const reducers = {
  messageTypes: messageTypesReducer,
  messages: messagesReducer,
  status: messagesStatusReducer,
  config: configReducer,
  preferences: preferencesReducer,
  transports: transportsReducer
};

export const selectAlertsState = createFeatureSelector<State>(
  'northstar-alerts'
);

export const getMessageTypesStoreState = createSelector(
  selectAlertsState,
  (state: State) => state.messageTypes
);

export const getMessagesStoreState = createSelector(
  selectAlertsState,
  (state: State) => state.messages
);

export const getMessagesStatusStoreState = createSelector(
  selectAlertsState,
  (state: State) => state.status
);

export const getConfigStoreState = createSelector(
  selectAlertsState,
  (state: State) => state.config
);

export const getPreferencesStoreState = createSelector(
  selectAlertsState,
  (state: State) => state.preferences
);

export const getTransportsStoreState = createSelector(
  selectAlertsState,
  (state: State) => state.transports
);
