import { MessageTypesActions, MessageTypesActionsUnion } from './actions';
import { MessageTypesState } from './state';

export const INITIAL_STATE: MessageTypesState = {
  loaded: false,
  loading: false,
  messageTypes: [],
  error: undefined
};

export function messageTypesReducer(
  state = INITIAL_STATE,
  action: MessageTypesActionsUnion
): MessageTypesState {
  switch (action.type) {
    case MessageTypesActions.Load:
      return {
        ...state,
        loading: true
      };
    case MessageTypesActions.LoadSuccess:
      return {
        ...state,
        loaded: true,
        loading: false,
        messageTypes: action.payload,
        error: undefined
      };
    case MessageTypesActions.LoadFail:
      return {
        ...state,
        loaded: false,
        loading: false,
        messageTypes: [],
        error: action.payload
      };
    default:
      return state;
  }
}
