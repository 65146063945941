import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';

@Component({
  selector: 'polaris-header-logo',
  templateUrl: './header-logo.component.html',
  styleUrls: ['./header-logo.component.scss'],
})
export class HeaderLogoComponent {
  version: string;
  
  constructor(private http: HttpClient) { }
  ngOnInit(): void {
    this.http.get('assets/version.txt', { responseType: 'text' })
      .subscribe((data: string) => {
        // Parse the content to extract the number
        this.version = 'v' + data;
      });
  }
}
