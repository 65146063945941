import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { hmrModule, bootloader } from '@angularclass/hmr';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

const main = () =>
  environment.hmr
    ? bootstrap().then((ngModuleRef) => hmrModule(ngModuleRef, module))
    : bootstrap();

bootloader(main);
