import { Injectable, Inject } from '@angular/core';
import { of } from 'rxjs';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as settings from './actions';
import { HttpClient } from '@angular/common/http';

import { exhaustMap, map, catchError, concatMap } from 'rxjs/operators';
import { EnvironmentConfig } from '../../types';
import { ENVIRONMENT_CONFIG } from '../../tokens';

@Injectable()
export class SettingsEffects {
  @Effect()
  public loadSettings$ = this.actions$.pipe(
    ofType(settings.SETTINGS_FETCH),
    exhaustMap(() => {
      const url = `${this.config.northstar.host.api}/api/settings`;

      return this.http.get<Record<string, unknown>>(url);
    }),
    map((res) => new settings.SettingsFetchSuccessAction(res || {})),
    catchError((err) =>
      of(new settings.SettingsFetchErrorAction(err.toString()))
    )
  );

  @Effect()
  public saveSettings$ = this.actions$.pipe(
    ofType(settings.SETTINGS_SAVE),
    concatMap((action: settings.SettingsSaveAction) => {
      const url = `${
        this.config.northstar.host.api
      }/api/settings/${action.payload.path.split('.').join('/')}`;

      return this.http.post(url, action.payload.setting).pipe(
        map(
          (res) =>
            new settings.SettingsSaveSuccessAction({
              requestId: action.payload.requestId,
              settings: res as Record<string, unknown>,
            })
        ),
        catchError((err) =>
          of(
            new settings.SettingsSaveErrorAction({
              requestId: action.payload.requestId,
              error: err.toString(),
            })
          )
        )
      );
    })
  );

  constructor(
    private http: HttpClient,
    @Inject(ENVIRONMENT_CONFIG) private config: EnvironmentConfig,
    private actions$: Actions
  ) {}
}
