import { Injectable } from '@angular/core';
import * as SockJS from 'sockjs-client';

@Injectable()
export class HMRService {
  private maxRetries = 10;
  private retry = this.maxRetries;
  private socket;
  private open = false;

  disconnect() {
    if (this.socket) {
      this.socket.close();
    }
    if (this.open) {
      this.open = false;
      this.socket = null;
    }
  }

  connect(serverUrl, port, handler) {
    this.disconnect();

    const [protocol, fullHost] = serverUrl.split('://');
    const [host] = fullHost.split(':');

    const socketUrl = `${protocol}://${host}:${port}/sockjs-node`;

    this.socket = new SockJS(socketUrl);

    this.socket.onopen = () => {
      this.open = true;
      this.retry = this.maxRetries;
    };

    this.socket.onclose = () => {
      this.socket = null;

      if (this.open) {
        this.open = false;
        const timeout =
          1000 * Math.pow(this.maxRetries - this.retry, 2) +
          Math.random() * 100;

        if (this.open || this.retry <= 0) {
          return;
        }

        setTimeout(() => {
          this.retry -= 1;

          this.connect(
            serverUrl,
            port,
            handler
          );
        }, timeout);
      }
    };

    this.socket.onmessage = event => {
      const message = JSON.parse(event.data);

      if (handler[message.type]) {
        handler[message.type](message.data);
      }
    };
  }
}
