import { MessagesActions, MessagesActionsUnion } from './actions';
import { MessagesState } from './state';

export const INITIAL_STATE: MessagesState = {
  loaded: false,
  loading: false,
  messages: [],
  error: undefined,
};

export function messagesReducer(
  state = INITIAL_STATE,
  action: MessagesActionsUnion
): MessagesState {
  switch (action.type) {
    case MessagesActions.Load:
      return {
        ...state,
        loading: true,
      };
    case MessagesActions.LoadSuccess:
      // eslint-disable-next-line no-case-declarations
      const messages = state.messages.concat();

      messages.splice(action.payload.from);

      return {
        ...state,
        loaded: true,
        loading: false,
        messages: messages.concat(
          action.payload.messages.map((message) => ({
            ...message,
            sentUtcDateTime: message.sentUtcDateTime.match(/Z$/)
              ? message.sentUtcDateTime
              : message.sentUtcDateTime + 'Z',
          }))
        ),
        error: undefined,
      };
    case MessagesActions.LoadFail:
      return {
        ...state,
        loaded: false,
        loading: false,
        messages: [],
        error: action.payload,
      };
    case MessagesActions.Read:
      // Optimistic update. Assume reading succeeds.
      return {
        ...state,
        messages: state.messages.concat().map((message) => {
          if (
            action.payload.deliveryIds.find((id) => id === message.deliveryId)
          ) {
            return {
              ...message,
              isRead: action.payload.read,
            };
          } else {
            return message;
          }
        }),
      };
    case MessagesActions.ReadFail:
      // Revert optimistic update on read failure.
      return {
        ...state,
        messages: state.messages.concat().map((message) => {
          if (
            action.payload.deliveryIds.find((id) => id === message.deliveryId)
          ) {
            return {
              ...message,
              isRead: !action.payload.read,
            };
          } else {
            return message;
          }
        }),
      };
    case MessagesActions.ReadAll:
      // Optimistic update. Assume reading succeeds.
      return {
        ...state,
        messages: state.messages.concat().map((message) => ({
          ...message,
          isRead: true,
        })),
      };
    case MessagesActions.Add:
      return {
        ...state,
        messages: [action.payload].concat(state.messages),
      };
    default:
      return state;
  }
}
