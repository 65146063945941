<ng-container *ngIf="tabItems">
  <header class="header-tabs c-header c-bg-gray-2">
    <ul class="c-nav c-nav-pills">
      <li *ngFor="let tab of tabItems" class="tab-item">
        <a
          class="header-tabs__tab c-nav-link c-text-gray-7 c-text-normal c-text-lg"
          (click)="doNavigate(tab.path)"
          [routerLink]="tab.path"
          [class.active]="isRouterLinkActive(tab)"
        >
          <i>
            <span
              [ngClass]="'c-nav-icon fa fa-' + tab.icon"
              aria-hidden="true"
            ></span>
          </i>
          <span class="header-tabs__tab-title c-p-left-sm">{{ tab.name }}</span>
        </a>
      </li>
    </ul>
  </header>
</ng-container>
