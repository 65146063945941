import {
  ViewChild,
  ViewChildren,
  Component,
  Input,
  Output,
  QueryList,
  AfterViewInit,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  DraggableLayout,
  LayoutChangeEventData,
} from '@ipreo/common-draggable-layout';
import { CardType } from '../../types';
import { CardComponent } from '../card/card.component';
import { CardContainerService } from './card-container.service';

@Component({
  selector: 'polaris-card-container',
  styleUrls: ['./card-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: ` <div #draggableLayout class="c-row layout-grid">
    <div
      #viewCards
      *ngFor="let card of cards; let ind = index"
      class="c-col-4 c-card-container p-card-container layout-grid-item"
      [attr.data-layout-id]="card.identifier"
      [attr.data-layout-position]="ind"
    >
      <polaris-card
        classes="c-card"
        [context]="card"
        (created)="cardCreated($event)"
      >
      </polaris-card>
    </div>
  </div>`,
})
export class CardContainerComponent implements AfterViewInit, OnChanges {
  @ViewChild('draggableLayout')
  draggableLayoutElement;
  @ViewChildren('viewCards')
  viewCards: QueryList<unknown>;
  @ViewChildren(CardComponent)
  cardComponents: QueryList<CardComponent>;

  @Input()
  public cards: CardType[];
  @Input()
  public override = 'northstar-cards-v1';
  @Output()
  public positionChange = new EventEmitter<LayoutChangeEventData>();
  @Output()
  public created = new EventEmitter<unknown>();

  constructor(private service: CardContainerService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      !this.cardArraysMatch(
        changes.cards.previousValue,
        changes.cards.currentValue
      )
    ) {
      if (changes.cards.currentValue.length) {
        this.service
          .loadModules(changes.cards.currentValue, this.override)
          .then((results) => {
            const cardComponentArray = this.cardComponents.toArray();
            results.forEach((result, index) => {
              if (cardComponentArray[index]) {
                cardComponentArray[index].setContentFactories(result);
              }
            });
          });
      }
    }
  }

  ngAfterViewInit(): void {
    const draggableLayout = new DraggableLayout(
      this.draggableLayoutElement.nativeElement,
      {
        dragSelector: '.c-card-header',
        onPositionChange: (data: LayoutChangeEventData) =>
          this.positionChange.emit(data),
      }
    );

    this.viewCards.changes.subscribe(() => {
      draggableLayout.refresh();
    });
  }

  cardCreated(data: unknown) {
    this.created.emit(data);
  }

  private cardArraysMatch(
    array1: CardType[] | null,
    array2: CardType[] | null
  ): boolean {
    if (!array1 || !array2 || array1.length !== array2.length) {
      return false;
    }

    for (let i = 0; i < array1.length; i++) {
      if (array2.indexOf(array1[i]) === -1) {
        return false;
      }
    }

    return true;
  }
}
