import { Injectable } from '@angular/core';
import { NMP } from '@ipreo/nmp-types';

@Injectable()
export class LoaderService {
  public loader: NMP.Loader;

  constructor() {
    this.loader = nmp.getLoader();
  }

  public setLoader(registryURL?: string, context?: string) {
    this.loader = nmp.getLoader(registryURL, context);
  }

  public moduleLoaded(name: string): boolean {
    return this.loader.isLoaded(name);
  }

  public loadByUrl(url: string, name?: string) {
    return this.loader.load(url).then((m) => {
      if (name) {
        this.loader.register(name, m, true);
      }
      return m;
    });
  }

  public loadModule(
    name: string,
    version: string
  ): Promise<NMP.LoaderResponse> {
    return this.loader.loadModule(name, version);
  }

  public loadModules(
    modules: Array<{ name: string; version: string }>
  ): Promise<NMP.LoaderResponse[]> {
    return this.loader.loadModules(modules);
  }
}
