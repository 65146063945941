import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of, from } from 'rxjs';
import {
  concatMap,
  mergeMap,
  map,
  catchError,
  withLatestFrom,
} from 'rxjs/operators';
import { getConfigStoreState, State } from '../reducer';
import { AlertsService } from '../../services/alerts.service';
import {
  MessagesActions,
  MessagesLoadAction,
  MessagesLoadFailAction,
  MessagesLoadSuccessAction,
  MessagesReadAction,
  MessagesReadSuccessAction,
  MessagesReadFailAction,
  MessagesReadAllSuccessAction,
  MessagesReadAllFailAction,
} from './actions';
import { MessagesStatusLoadAction } from '../messages-status/actions';
import { MESSAGE_LOAD_LIMIT } from '../../tokens';

@Injectable()
export class MessagesEffects {
  @Effect()
  public loadMessages$: Observable<
    MessagesLoadSuccessAction | MessagesLoadFailAction
  > = this.actions$
    .pipe(
      ofType(MessagesActions.Load),
      withLatestFrom(this.store.pipe(select(getConfigStoreState)))
    )
    .pipe(
      concatMap(([action, config]) => {
        const payload = (action as MessagesLoadAction).payload;
        return this.alertsService.getMessages(payload, config).pipe(
          map(
            (messages) =>
              new MessagesLoadSuccessAction({
                messages,
                from: payload.from || 0,
              })
          ),
          catchError((error) => of(new MessagesLoadFailAction(error)))
        );
      })
    );

  @Effect()
  public readMessages$: Observable<
    MessagesReadSuccessAction | MessagesReadFailAction
  > = this.actions$
    .pipe(
      ofType(MessagesActions.Read),
      withLatestFrom(this.store.pipe(select(getConfigStoreState)))
    )
    .pipe(
      mergeMap(([action, config]) => {
        const { deliveryIds, read } = (action as MessagesReadAction).payload;
        return this.alertsService.readMessages(deliveryIds, read, config).pipe(
          map(() => new MessagesReadSuccessAction()),
          catchError((error) =>
            of(new MessagesReadFailAction({ deliveryIds, read, error }))
          )
        );
      })
    );

  @Effect()
  public readAllMessages$: Observable<
    | MessagesReadAllSuccessAction
    | MessagesReadAllFailAction
    | MessagesLoadAction
    | MessagesStatusLoadAction
  > = this.actions$
    .pipe(
      ofType(MessagesActions.ReadAll),
      withLatestFrom(this.store.pipe(select(getConfigStoreState)))
    )
    .pipe(
      mergeMap(([, config]) => {
        return this.alertsService.readAllMessages(config).pipe(
          map(() => new MessagesReadAllSuccessAction()),
          catchError((error) => {
            return from([
              new MessagesReadAllFailAction(error),
              new MessagesLoadAction({ from: MESSAGE_LOAD_LIMIT }),
              new MessagesStatusLoadAction(),
            ]);
          })
        );
      })
    );

  constructor(
    private actions$: Actions,
    private alertsService: AlertsService,
    private store: Store<State>
  ) {}
}
