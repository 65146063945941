import { Injectable, Inject } from '@angular/core';
import { of } from 'rxjs';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  FeaturesFetchSuccessAction,
  FeaturesFetchErrorAction,
  FEATURES_FETCH
} from './actions';
import { HttpClient } from '@angular/common/http';
import { EnvironmentConfig } from '../../types';
import { ENVIRONMENT_CONFIG } from '../../tokens';
import { exhaustMap, map, catchError } from 'rxjs/operators';
import { HomeFeaturesResponse } from './state';

@Injectable()
export class FeaturesEffects {
  @Effect()
  public loadFeatures$ = this.actions$.pipe(
    ofType(FEATURES_FETCH),
    exhaustMap(() => {
      const clientEnv = window.sessionStorage.getItem('client_env');
      const override = window.location.search.match(/override=([^&]+)/);
      const url = `${this.config.northstar.host.api}/api/features`;

      const params = {};
      if (clientEnv) {
        params['clientEnv'] = clientEnv;
      }
      if (override) {
        params['override'] = override[1];
      }

      return this.http.get<HomeFeaturesResponse>(url, { params });
    }),
    map(res => new FeaturesFetchSuccessAction(res)),
    catchError(e => of(new FeaturesFetchErrorAction(e.toString())))
  );

  constructor(
    private http: HttpClient,
    @Inject(ENVIRONMENT_CONFIG) private config: EnvironmentConfig,
    private actions$: Actions
  ) { }

}
