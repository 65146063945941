/* eslint-disable @typescript-eslint/no-explicit-any */
import { DevModuleType, DevModule } from '@ipreo/northstar';
import { LoadChildrenCallback } from '@angular/router';

const demoAppPackage = require('../../../../libs/demo-app/package.json');
const demoCardPackage = require('../../../../libs/demo-card/package.json');
const workbenchPackage = require('../../../../libs/workbench/package.json');

export function createDevModule(
  packageMeta: any,
  path: string,
  moduleType: DevModuleType,
  loadChildren: LoadChildrenCallback
): DevModule {
  return {
    moduleType: moduleType,
    path: path,
    loadChildren,
    manifest: {
      name: packageMeta.name,
      version: packageMeta.version,
      ...packageMeta.nmp,
    },
  };
}

export const devModules = [
  createDevModule(
    demoAppPackage,
    'demo-app/:id',
    DevModuleType.route,
    () => import('@ipreo/demo-app')
  ),
  createDevModule(
    workbenchPackage,
    'workbench',
    DevModuleType.route,
    () => import('@ipreo/workbench')
  ),
  createDevModule(
    demoCardPackage,
    'demo-card',
    DevModuleType.component,
    () => import('@ipreo/demo-card')
  ),
];
