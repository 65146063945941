<polaris-header [@fadeIn] [headerItems]="headerItems">
  <!-- Commenting since API for northstar-alerts is decommissioned-->
  <!-- <northstar-alerts
    header-right
    [token]="token"
    appCode="bsp"
  ></northstar-alerts> -->
</polaris-header>
<main class="main-container">
  <div
    [@fadeOut]
    class="p-page-loader"
    *ngIf="(loading | async) || (isLoading | async)"
  >
    <div class="c-full-page-loader"></div>
  </div>
  <div class="main-content">
    <router-outlet></router-outlet>
  </div>
</main>
<spr-footer [expandable]="true" [sticky]="true"></spr-footer>