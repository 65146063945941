import { PreferencesActions, PreferencesActionsUnion } from './actions';
import { PreferencesState } from './state';

export const INITIAL_STATE: PreferencesState = {
  loaded: false,
  loading: false,
  preferences: [],
  error: undefined
};

export function preferencesReducer(
  state = INITIAL_STATE,
  action: PreferencesActionsUnion
): PreferencesState {
  switch (action.type) {
    case PreferencesActions.Load:
      return {
        ...state,
        loading: true
      };
    case PreferencesActions.LoadSuccess:
      return {
        ...state,
        loaded: true,
        loading: false,
        preferences: action.payload,
        error: undefined
      };
    case PreferencesActions.LoadFail:
      return {
        ...state,
        loaded: false,
        loading: false,
        preferences: [],
        error: action.payload
      };
    case PreferencesActions.Update:
      // Optimistic update. Assume preference update succeeds.
      return {
        ...state,
        preferences: state.preferences.concat().map(preference => {
          if (action.payload.messageTypeId === preference.messageTypeId) {
            return {
              ...preference,
              active: action.payload.active
            };
          } else {
            return preference;
          }
        })
      };
    case PreferencesActions.UpdateFail:
      // Revert optimistic update on preference update failure.
      return {
        ...state,
        preferences: state.preferences.concat().map(preference => {
          if (
            action.payload.request.messageTypeId === preference.messageTypeId
          ) {
            return {
              ...preference,
              active: !action.payload.request.active
            };
          } else {
            return preference;
          }
        })
      };
    default:
      return state;
  }
}
