import { Injectable } from '@angular/core';
import { PolarisService } from '../../services/polaris.service';
import { CompilationService } from '../../services/compilation.service';
import { CardType, CardModuleConfig, ModuleFactories } from '../../types';
import { LogService } from '../../services/log/log.service';
import { ErrorModule } from '../error/error.module';

@Injectable()
export class CardContainerService {
  constructor(
    private polarisService: PolarisService,
    private compilationService: CompilationService,
    private logService: LogService
  ) {}

  private loadErrorComponent() {
    return this.compilationService.compileModuleAndComponent(
      { ErrorModule },
      'ErrorModule',
      { moduleName: 'ErrorModule', selector: 'error-stub' }
    );
  }

  public loadModules(
    cards: CardType[],
    override: string
  ): Promise<ModuleFactories[]> {
    const moduleLogData = {
      modules: cards.map((card) => {
        return `${card.name}@${card.version}`;
      }),
    };

    this.logService.event('Multi-Module Load Start', moduleLogData);

    return this.polarisService
      .loadAngularComponents(
        cards,
        (manifest) => {
          const moduleConfig: CardModuleConfig = manifest.platforms[
            override
          ] as CardModuleConfig;
          const ngConfig: CardModuleConfig = manifest.platforms[
            'angular'
          ] as CardModuleConfig;

          return {
            moduleName:
              (moduleConfig && moduleConfig.moduleName) || ngConfig.moduleName,
            selector:
              (moduleConfig && moduleConfig.selector) || ngConfig.selector,
          };
        },
        (err, manifest) => {
          this.logService.error(
            `Failed to Load Module ${manifest.name}@${manifest.version}`,
            err as string
          );
          return this.loadErrorComponent();
        }
      )
      .catch((err) => {
        this.logService.error('Multi-Module Load Failure', {
          ...moduleLogData,
          error: moduleLogData,
        });
        throw err;
      });
  }
}
