import { Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { NoContentComponent } from './no-content/no-content.component';

export const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    pathMatch: 'full'
  },
  {
    path: '**',
    component: NoContentComponent
  }
];
