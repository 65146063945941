import { Component, OnInit, Input } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';

export enum HeaderItemType {
  link = 'link',
  dropdown = 'dropdown',
  info = 'info',
  user = 'user'
}

export enum HeaderGroup {
  left,
  center,
  right,
}

export interface HeaderBaseLinkParams {
  classes?: string[];
}

export interface HeaderPathLinkParams {
  path: string;
}

export interface HeaderEventLinkParams {
  event: (event: unknown) => void;
}

export interface HeaderNamedLinkParams {
  name: string;
  icon?: string;
}

export interface HeaderWrapperLinkParams {
  innerHtml: string | SafeHtml;
}

export type HeaderNamedPathLinkParams = HeaderBaseLinkParams &
  HeaderPathLinkParams &
  HeaderNamedLinkParams;

export type HeaderWrapperPathLinkParams = HeaderBaseLinkParams &
  HeaderPathLinkParams &
  HeaderWrapperLinkParams;

export type HeaderNamedEventLinkParams = HeaderBaseLinkParams &
  HeaderEventLinkParams &
  HeaderNamedLinkParams;

export type HeaderWrapperEventLinkParams = HeaderBaseLinkParams &
  HeaderEventLinkParams &
  HeaderWrapperLinkParams;

export type HeaderLinkParams =
  | HeaderNamedPathLinkParams
  | HeaderWrapperPathLinkParams
  | HeaderNamedEventLinkParams
  | HeaderWrapperEventLinkParams;

export interface HeaderBaseDropdownParams {
  menuItems: Array<HeaderLinkParams>;
}

export interface HeaderOptionalPathLinkParams {
  path?: string;
}

export interface HeaderOptionalEventLinkParams {
  event?: (event: unknown) => void;
}

export type HeaderNamedPathDropdownParams = HeaderBaseLinkParams &
  HeaderOptionalPathLinkParams &
  HeaderNamedLinkParams &
  HeaderBaseDropdownParams;

export type HeaderWrapperPathDropdownParams = HeaderBaseLinkParams &
  HeaderOptionalPathLinkParams &
  HeaderWrapperLinkParams &
  HeaderBaseDropdownParams;

export type HeaderNamedEventDropdownParams = HeaderBaseLinkParams &
  HeaderOptionalEventLinkParams &
  HeaderNamedLinkParams &
  HeaderBaseDropdownParams;

export type HeaderWrapperEventDropdownParams = HeaderBaseLinkParams &
  HeaderOptionalEventLinkParams &
  HeaderWrapperLinkParams &
  HeaderBaseDropdownParams;

export type HeaderDropdownParams =
  | HeaderNamedPathDropdownParams
  | HeaderWrapperPathDropdownParams
  | HeaderNamedEventDropdownParams
  | HeaderWrapperEventDropdownParams;

export interface HeaderInfoParams {
  name: string;
  icon?: string;
  innerHtml?: string;
}

export interface HeaderUserParams {
  name: string;
  email: string;
  menuItems: HeaderLinkParams[];
}

export type HeaderItemParams =
  | HeaderLinkParams
  | HeaderDropdownParams
  | HeaderInfoParams
  | HeaderUserParams;

export interface HeaderItem {
  type: HeaderItemType;
  group: HeaderGroup;
  params: HeaderItemParams;
}

@Component({
  selector: 'polaris-header',
  templateUrl: './new-header.component.html',
  styleUrls: ['./new-header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input()
  public headerItems: Observable<HeaderItem[]>;

  public headerLeft: HeaderItem[];
  public headerCenter: HeaderItem[];
  public headerRight: HeaderItem[];
  public useHeaderPills: boolean;

  ngOnInit() {
    this.headerItems.subscribe((headerItems) => {
      this.headerLeft = headerItems.filter(
        (item) => item.group === HeaderGroup.left
      );
      this.headerCenter = headerItems.filter(
        (item) => item.group === HeaderGroup.center
      );

      this.headerRight = headerItems.filter(
        (item) => item.group === HeaderGroup.right
      );

      this.useHeaderPills = !headerItems.filter(
        (item) => item.type === HeaderItemType.dropdown
      ).length;
    });
  }
}
