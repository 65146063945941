import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter } from 'rxjs/operators';

import { Navigation } from '@ipreo/northstar';

import { HeaderItem, HeaderItemType } from '../../header.component';

export interface TabItem {
  icon: string;
  name: string;
  path: string;
  exactPathMatch: boolean;
  displayOrder: number;
}

export interface HeaderTabConfig {
  key: string;
  name: string;
  exactPathMatch: boolean;
  displayOrder: number;
}

@Component({
  selector: 'polaris-header-tabs',
  templateUrl: './header-tabs.component.html',
  styleUrls: ['./header-tabs.component.scss'],
})
export class HeaderTabsComponent implements OnInit {
  @Input()
  set headerItems(items: HeaderItem[]) {
    if (items) {
      this.setTabItems(items);
    }
  }

  private headerTabConfig: HeaderTabConfig[] = [
    {
      key: 'mydeals',
      name: 'Deal List',
      exactPathMatch: true,
      displayOrder: 1,
    },
    {
      key: 'streetcalendar',
      name: 'Street Calendar',
      exactPathMatch: false,
      displayOrder: 2,
    },
    {
      key: 'dealmanager',
      name: 'Deal Manager',
      exactPathMatch: false,
      displayOrder: 3,
    },
    {
      key: 'bidcomp',
      name: 'Bid Comp',
      exactPathMatch: false,
      displayOrder: 4,
    },
    {
      key: 'dealquery',
      name: 'Deal Query',
      exactPathMatch: false,
      displayOrder: 5,
    },
  ];

  public tabItems: TabItem[];

  public currentRoute = '';

  constructor(private router: Router) {}

  ngOnInit() {
    this.currentRoute = this.router.url;

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((state: NavigationEnd) => {
        this.currentRoute = state.url;
      });
  }

  private setTabItems(items: HeaderItem[]): void {
    const tabItems: TabItem[] = [];

    items.forEach(({ type, params }) => {
      if (type === HeaderItemType.dropdown) {
        (params as any).menuItems.forEach((menuItem: Navigation) => {
          const headerTabConfig = this.getHeaderConfig(menuItem);

          if (headerTabConfig) {
            tabItems.push(this.setTabItem(menuItem, headerTabConfig));
          }
        });
      } else if (type === HeaderItemType.link) {
        const menuItem = params as Navigation;
        const headerTabConfig = this.getHeaderConfig(menuItem);

        if (headerTabConfig) {
          tabItems.push(this.setTabItem(menuItem, headerTabConfig));
        }
      }
    });

    if (tabItems.length) {
      this.tabItems = tabItems.sort((a, b) => a.displayOrder - b.displayOrder);
    }
  }

  private setTabItem(
    menuItem: Navigation,
    headerTabConfig: HeaderTabConfig
  ): TabItem {
    return {
      icon: menuItem.icon,
      path: menuItem.path,
      name: headerTabConfig.name,
      exactPathMatch: headerTabConfig.exactPathMatch,
      displayOrder: headerTabConfig.displayOrder,
    };
  }

  private getHeaderConfig(menuItem: Navigation): HeaderTabConfig {
    const menuKey = this.getMenuKey(menuItem.name);

    return this.headerTabConfig.find((c) => c.key === menuKey);
  }

  private getMenuKey(name: string): string {
    return name.replace(/ /g, '').toLowerCase();
  }

  public doNavigate(route: string): void {
    this.router.navigateByUrl(route);
  }

  public isRouterLinkActive({ path, exactPathMatch }: TabItem): boolean {
    return exactPathMatch
      ? this.currentRoute === path
      : this.currentRoute.includes(path);
  }
}
