import {
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';

import { BehaviorSubject, Subscription } from 'rxjs';

import { HeaderPathLinkParams, HeaderUserParams } from '../../header.component';

@Component({
  selector: 'polaris-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit, OnDestroy {
  @HostBinding('class.c-header-item')
  item = true;

  @Input()
  public params: HeaderUserParams;

  public active = new BehaviorSubject(false);

  public isDropdownVisible = false;

  private routerSubscription: Subscription;

  constructor(private router: Router) {}

  ngOnInit() {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof RoutesRecognized) {
        this.isDropdownVisible = false;
        this.active.next(this.urlMatchesMyTree(event.url));
      }
    });
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  public toggleDropdown(): void {
    this.isDropdownVisible = !this.isDropdownVisible;
  }

  private urlMatchesMyTree(url: string): boolean {
    const isMyPath = (this.params as any).path === url.split('?')[0];

    return (
      isMyPath ||
      (this.params.menuItems || []).some((item) => {
        const path = (item as HeaderPathLinkParams).path;

        return url.startsWith(path) || url === path;
      })
    );
  }

  public getUserInitials(): string {
    return this.params.name
      .split(' ')
      .reduce((acc, v) => acc + v[0].toUpperCase(), '');
  }
}
