import * as actions from './actions';
import { DevServersState } from './state';

export const INITIAL_STATE: DevServersState = { servers: [] };

export function devServersReducer(
  state = INITIAL_STATE,
  action: actions.DevServerActions
): DevServersState {
  switch (action.type) {
    case actions.DEV_SERVERS_FETCH_MANIFESTS_SUCCESS:
      return {
        servers: state.servers.map((server) => {
          if (action.payload[server.url]) {
            return {
              ...server,
              ...action.payload[server.url],
              enabled: !action.payload[server.url].error,
              loading: false,
            };
          } else {
            return {
              ...server,
              enabled: false,
              loading: false,
              error: 'Not Loaded',
              manifest: undefined,
            };
          }
        }),
      };
    case actions.DEV_SERVERS_ADD:
      // eslint-disable-next-line no-case-declarations
      const existingIndex = state.servers.findIndex(
        (server) => server.url === action.payload
      );

      if (existingIndex > -1) {
        return {
          servers: state.servers.map((server) => {
            if (server.url === action.payload) {
              return {
                url: server.url,
                enabled: false,
                loading: true,
              };
            } else {
              return server;
            }
          }),
        };
      } else {
        return {
          servers: [
            ...state.servers,
            {
              url: action.payload,
              loading: true,
              enabled: false,
            },
          ],
        };
      }
    case actions.DEV_SERVERS_ADD_SUCCESS:
      return {
        servers: state.servers.map((server) => {
          if (server.url === action.payload.url) {
            return {
              ...action.payload,
              enabled: !action.payload.error,
              loading: false,
            };
          } else {
            return server;
          }
        }),
      };
    case actions.DEV_SERVERS_REMOVE:
      // eslint-disable-next-line no-case-declarations
      const indexToRemove = state.servers.findIndex(
        (server) => server.url === action.payload
      );

      if (indexToRemove > -1) {
        return {
          servers: [
            ...state.servers.slice(0, indexToRemove),
            ...state.servers.slice(indexToRemove + 1),
          ],
        };
      } else {
        return state;
      }
    case actions.DEV_SERVERS_CLEAR:
      return INITIAL_STATE;
    default:
      return state;
  }
}
