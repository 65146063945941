import { Injectable } from '@angular/core';
import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { filter, map, distinctUntilChanged } from 'rxjs/operators';
import { Navigation, ManualDevAppManifest } from '../types';
import { Store, select } from '@ngrx/store';
import { State } from '../state/reducer';
import { FeaturesState } from '../state/features/state';
import { DevServersState, DevServer } from '../state/dev-servers/state';
import { getFeaturesState, getDevServersState } from '../state/reducer';
import { getCurrentEnvironment } from '../env-util';

@Injectable()
export class NavigationService {
  constructor(private store: Store<{ northstar: State }>) {}
  private devServers: Observable<DevServer[]> = this.store.pipe(
    select(getDevServersState),
    map((state: DevServersState) =>
      state.servers.filter((server: DevServer) => server.enabled)
    ),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
  );

  private featureNavigation: Observable<Navigation[]> = this.store.pipe(
    select(getFeaturesState),
    filter((state: FeaturesState) => state.loaded),
    map((state: FeaturesState) => state.navigation),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
  );

  private devServerNavigation: Observable<Navigation[]> = this.devServers.pipe(
    map((servers) => {
      const env = getCurrentEnvironment();

      return servers.reduce((acc, server: DevServer) => {
        const navigation = Object.prototype.hasOwnProperty.call(
          server.manifest,
          env
        )
          ? server.manifest[env].navigation
          : [];
        return acc.concat(navigation);
      }, []);
    })
  );

  private manualDevAppManifest = new BehaviorSubject<ManualDevAppManifest>(
    null
  );

  public items: Observable<Navigation[]> = combineLatest([
    this.featureNavigation,
    this.devServerNavigation,
    this.manualDevAppManifest,
  ]).pipe(
    map(([featureNav, devServerNav, manualDevAppManifest]) => {
      const newNav = featureNav.concat(devServerNav);
      if (manualDevAppManifest) {
        newNav.push({
          name: manualDevAppManifest.navName,
          icon: 'gear',
          path: manualDevAppManifest.path,
        });
      }
      return newNav;
    })
  );
}
