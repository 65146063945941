import { Action } from '@ngrx/store';
import {
  MessageTypePreference,
  MessageTypePreferenceRequest
} from '../../types';

export enum PreferencesActions {
  Load = '[northstar-alerts] Load Preferences',
  LoadSuccess = '[northstar-alerts] Load Preferences Success',
  LoadFail = '[northstar-alerts] Load Preferences Error',
  Update = '[northstar-alerts] Update Preference',
  UpdateFail = '[northstar-alerts] Update Preference Error'
}

/* tslint:disable:max-classes-per-file */

export class PreferencesLoadAction implements Action {
  public readonly type = PreferencesActions.Load;
}

export class PreferencesLoadSuccessAction implements Action {
  public readonly type = PreferencesActions.LoadSuccess;

  constructor(public payload: MessageTypePreference[]) {}
}

export class PreferencesLoadFailAction implements Action {
  public readonly type = PreferencesActions.LoadFail;

  constructor(public payload: string) {}
}

export class PreferencesUpdateAction implements Action {
  public readonly type = PreferencesActions.Update;

  constructor(public payload: MessageTypePreferenceRequest) {}
}
export class PreferencesUpdateFailAction implements Action {
  public readonly type = PreferencesActions.UpdateFail;

  constructor(
    public payload: {
      request: MessageTypePreferenceRequest;
      error: string;
    }
  ) {}
}

export type PreferencesActionsUnion =
  | PreferencesLoadAction
  | PreferencesLoadSuccessAction
  | PreferencesLoadFailAction
  | PreferencesUpdateAction
  | PreferencesUpdateFailAction;
