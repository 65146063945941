import { ConfigActions, ConfigActionsUnion } from './actions';
import { ConfigState } from './state';

export const INITIAL_STATE: ConfigState = {
  token: undefined,
  appCode: undefined
};

export function configReducer(
  state = INITIAL_STATE,
  action: ConfigActionsUnion
): ConfigState {
  switch (action.type) {
    case ConfigActions.SetToken:
      return {
        ...state,
        token: action.payload
      };
    case ConfigActions.SetAppCode:
      return {
        ...state,
        appCode: action.payload
      };
    default:
      return state;
  }
}
