import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PopoverModule, SwitchModule } from '@ipreo/ngx-sprinkles';
import { reducers } from './state/reducer';
import { AlertsComponent } from './components/alerts/alerts.component';
import { PaneComponent } from './components/pane/pane.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AlertsService } from './services/alerts.service';
import { DesktopNotificationService } from './services/desktop-notifications.service';
import { MessageTypesEffects } from './state/message-types/effects';
import { MessagesEffects } from './state/messages/effects';
import { MessagesStatusEffects } from './state/messages-status/effects';
import { ConfigEffects } from './state/config/effects';
import { PreferencesEffects } from './state/preferences/effects';
import { TransportsEffects } from './state/transports/effects';

export const storeModule = StoreModule.forFeature('northstar-alerts', reducers);
export const effectModule = EffectsModule.forFeature([
  MessageTypesEffects,
  MessagesEffects,
  MessagesStatusEffects,
  ConfigEffects,
  PreferencesEffects,
  TransportsEffects,
]);

@NgModule({
  declarations: [AlertsComponent, PaneComponent, SettingsComponent],
  imports: [
    CommonModule,
    FormsModule,
    PopoverModule,
    SwitchModule,
    storeModule,
    effectModule,
  ],
  providers: [AlertsService, DesktopNotificationService],
  exports: [AlertsComponent],
})
export class NorthstarAlertsModule {}
