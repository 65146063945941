import { Injectable, Inject } from '@angular/core';
import {
  Route,
  Router,
  NavigationEnd,
  NavigationStart,
  RoutesRecognized,
} from '@angular/router';
import { Observable, of, combineLatest, BehaviorSubject } from 'rxjs';
import {
  filter,
  map,
  distinctUntilChanged,
  delayWhen,
  startWith,
  take,
  delay,
  first,
} from 'rxjs/operators';
import { NMP } from '@ipreo/nmp-types';
import {
  RouteManifest,
  EnvironmentConfig,
  ModuleFactoryManifest,
  DevRoute,
  ManualDevAppManifest,
  DevModule,
  DevModuleType,
} from '../types';
import { ENVIRONMENT_CONFIG, DEV_MODULES } from '../tokens';
import { HMRService } from './hmr.service';
import { PolarisService } from './polaris.service';
import { WrapperNgModuleFactory } from './util/wrapper-module.factory';
import { Store, select } from '@ngrx/store';
import { State } from '../state/reducer';
import { FeaturesState } from '../state/features/state';
import {
  AddDevServerAction,
  ClearDevServerAction,
} from '../state/dev-servers/actions';
import { DevServersState, DevServer } from '../state/dev-servers/state';
import { getFeaturesState, getDevServersState } from '../state/reducer';
import { CompilationService } from './compilation.service';
import { getCurrentEnvironment } from '../env-util';

@Injectable()
export class RoutingService {
  private featureRoutes: Observable<RouteManifest[]> = this.store.pipe(
    select(getFeaturesState),
    filter((state: FeaturesState) => state.loaded),
    map((state: FeaturesState) => state.routes)
  );

  private defaultRoute: Observable<string> = this.store.pipe(
    select(getFeaturesState),
    filter((state: FeaturesState) => state.loaded),
    map((state: FeaturesState) => state.defaultRoute)
  );

  private devServers: Observable<DevServer[]> = this.store.pipe(
    select(getDevServersState),
    map((state: DevServersState) =>
      state.servers.filter((server: DevServer) => server.enabled)
    ),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
  );

  private baseRouterConfig: Route[];

  private connectedDevServerRoute: DevRoute;
  private connectedManualDevRoute: ManualDevAppManifest;

  private manualDevAppManifest = new BehaviorSubject<ManualDevAppManifest>(
    null
  );

  private devRoutes: DevModule[];

  constructor(
    private store: Store<{ northstar: State }>,
    private router: Router,
    private hmrService: HMRService,
    private polarisService: PolarisService,
    private compilationService: CompilationService,
    @Inject(ENVIRONMENT_CONFIG) private envConfig: EnvironmentConfig,
    @Inject(DEV_MODULES) devModules: DevModule[]
  ) {
    this.devRoutes = devModules.filter(
      (module) => module.moduleType === DevModuleType.route
    );

    this.baseRouterConfig = this.router.config.filter(
      (route) =>
        !this.devRoutes.find((devRoute) => devRoute.path === route.path)
    );
  }

  public start(reset = false) {
    let routerStatus;

    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd || event instanceof NavigationStart
        ),
        map((event) => (event instanceof NavigationStart ? 'busy' : 'idle')),
        startWith('idle')
      )
      .subscribe((e) => (routerStatus = e));

    const searchParams = new URL(document.location.href).searchParams;

    if (searchParams.get('clearDevServers') === 'true' || reset) {
      this.store.dispatch(new ClearDevServerAction());
    }

    const urlBasedDevServer = searchParams.get('devServer');
    let manualDevServerLoaded: Observable<boolean>;

    if (urlBasedDevServer && !reset) {
      this.store.dispatch(new AddDevServerAction(urlBasedDevServer));

      manualDevServerLoaded = this.store.pipe(
        select(getDevServersState),
        map(
          (state: DevServersState) =>
            state.servers.filter(
              (server: DevServer) => server.url === urlBasedDevServer
            )[0]
        ),
        filter((server) => server && !server.loading),
        first(),
        map(() => true)
      );
    } else {
      manualDevServerLoaded = of(true);
    }

    const allRoutes = combineLatest([
      this.featureRoutes,
      this.devServers,
      this.manualDevAppManifest,
      manualDevServerLoaded,
      this.defaultRoute,
    ]).pipe(
      distinctUntilChanged(),
      // This ensures that we don't reset the router while it is routing, which causes errors.
      // Note that we tried to make this work with pure observables, but were unsuccessful. Some form of RxJS magic should work.
      delayWhen(() => {
        if (routerStatus === 'idle') {
          return of(true);
        } else {
          return this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
          );
        }
      })
    );

    allRoutes.subscribe(
      ([
        featureRouteManifests,
        devServers,
        manualDevAppManifest,
        ,
        featuresDefaultRoute,
      ]) => {
        const currentRoutes = this.router.config;

        const featureRoutes = (featureRouteManifests || []).map(
          (routeManifest) => {
            const devRoute = this.devRoutes.find(
              (route) => route.path === routeManifest.path
            );
            if (devRoute) {
              return this.routeForLocalDevModule(devRoute, routeManifest);
            } else {
              return this.routeForRouteManifest(routeManifest);
            }
          }
        );

        let devRoutes: Route[] = [];
        const env = getCurrentEnvironment();
        devServers.forEach((devServer) => {
          const envRoutes = Object.prototype.hasOwnProperty.call(
            devServer.manifest,
            env
          )
            ? devServer.manifest[env].routes
            : [];
          devRoutes = devRoutes.concat(
            envRoutes.map((devRoute) => {
              const currentRoute = currentRoutes.find(
                (route) => route.path === devRoute.path
              );
              if (currentRoute) {
                return currentRoute;
              } else {
                return this.routeForDevServerRoute(devRoute);
              }
            })
          );
        });

        const manualRoutes = manualDevAppManifest
          ? [this.routeForManualDevServerManifest(manualDevAppManifest)]
          : [];

        const config = [
          ...manualRoutes,
          ...featureRoutes,
          ...devRoutes,
          ...this.baseRouterConfig,
        ];

        const [
          { manifest: { defaultRoute = featuresDefaultRoute } = {} } = {},
        ] = devServers;

        if (defaultRoute) {
          config.splice(
            config.findIndex((r) => !r.path),
            1,
            {
              path: '',
              redirectTo: defaultRoute,
              pathMatch: 'full',
            }
          );
        }

        this.router.resetConfig(config);
      }
    );

    // Start navigation after the routes are first loaded.
    allRoutes
      .pipe(take(1), delay(50))
      .subscribe(() => this.router.initialNavigation());

    // Keep an eye on dev server routes and kill HMR if they unload
    combineLatest([
      this.router.events.pipe(filter((e) => e instanceof RoutesRecognized)),
      this.devServers,
      this.manualDevAppManifest,
    ]).subscribe(([event, servers, manualDevAppManifest]) => {
      const url = (event as RoutesRecognized).url;
      if (
        this.connectedDevServerRoute &&
        !url.startsWith(`/${this.connectedDevServerRoute.path}`)
      ) {
        this.disconnectHMR();
      }
      if (
        this.connectedManualDevRoute &&
        !url.startsWith(`/${this.connectedManualDevRoute.path}`)
      ) {
        this.disconnectHMR();
      }

      if (
        manualDevAppManifest &&
        url.startsWith(`/${manualDevAppManifest.path}`)
      ) {
        this.connectHMRForManualDevRoute(manualDevAppManifest);
      }

      const env = getCurrentEnvironment();
      servers.forEach((devServer: DevServer) => {
        const envRoutes = Object.prototype.hasOwnProperty.call(
          devServer.manifest,
          env
        )
          ? devServer.manifest[env].routes
          : [];
        const matchedRoute = envRoutes.find((route) =>
          url.startsWith(`/${route.path}`)
        );

        if (matchedRoute && matchedRoute !== this.connectedDevServerRoute) {
          this.connectHMRForDevRoute(matchedRoute, devServer);
        }
      });
    });

    if (reset) {
      this.router.navigate([this.router.url.split('?')[0]]);
    }
  }

  //////////////////////////////
  // Manual Dev App Handlers  //
  //////////////////////////////

  public registerManualDevApp(
    moduleName: string,
    url: string,
    navName: string,
    params: Record<string, unknown>,
    path: string = 'manual-app'
  ) {
    this.manualDevAppManifest.next({
      moduleName,
      url,
      navName,
      params,
      path,
    });
  }

  public removeManualDevRoute() {
    this.manualDevAppManifest.next(null);
  }

  ////////////////////////////
  // Route Creators //////////
  ////////////////////////////

  // Route for routes from the Features API
  private routeForRouteManifest(routeManifest: RouteManifest): Route {
    return {
      path: routeManifest.path,
      loadChildren: this.loadChildrenForRouteManifest(routeManifest),
    };
  }

  // Route for routes listed in a Dev Server Manifest
  private routeForDevServerRoute(route: DevRoute): Route {
    return {
      path: route.path,
      loadChildren: this.loadChildrenForDevServerModule(route),
    };
  }

  // Route for local polaris development purposes only
  private routeForLocalDevModule(
    devRoute: DevModule,
    routeManifest: RouteManifest
  ): Route {
    return {
      path: routeManifest.path,
      loadChildren: async () => {
        const module = await (devRoute.loadChildren as () => unknown)();

        const moduleFactoryResult = await this.compilationService.compileModule(
          {
            module,
            manifest: {
              name: devRoute.manifest.name,
              version: devRoute.manifest.version,
              jsBundle: `Dynamically loaded version of ${devRoute.manifest.name}`,
              platforms: devRoute.manifest.platforms,
            } as NMP.ModuleManifest,
          }
        );

        const wrappedModuleFactory = new WrapperNgModuleFactory(
          moduleFactoryResult.moduleFactory,
          this.envConfig,
          routeManifest.module.params || routeManifest.module.parameters,
          routeManifest.module.name,
          routeManifest.module.version
        );
        return wrappedModuleFactory;
      },
    };
  }

  // Route for manually added app from the workbench
  private routeForManualDevServerManifest(
    manualDevAppManifest: ManualDevAppManifest
  ): Route {
    return {
      path: manualDevAppManifest.path,
      loadChildren: this.loadChildrenForModuleByUrl(
        manualDevAppManifest.url,
        manualDevAppManifest.moduleName,
        'dev',
        manualDevAppManifest.params,
        this.envConfig
      ),
    };
  }

  ////////////////////////////
  // Load Children Creators //
  ////////////////////////////

  private loadChildrenForRouteManifest(routeManifest: RouteManifest) {
    return () => {
      const module = routeManifest.module;

      return this.polarisService
        .loadModule(module.name, module.version)
        .then((result: NMP.LoaderResponse) =>
          this.compilationService.compileModule(result)
        )
        .then((m: ModuleFactoryManifest) => {
          const wrappedModuleFactory = new WrapperNgModuleFactory(
            m.moduleFactory,
            this.envConfig,
            routeManifest.module.params || routeManifest.module.parameters,
            routeManifest.module.name,
            routeManifest.module.version
          );
          return wrappedModuleFactory;
        });
    };
  }

  private loadChildrenForDevServerModule(route: DevRoute) {
    return () => {
      return this.polarisService
        .loadModule(route.module, 'dev')
        .then((result: NMP.LoaderResponse) =>
          this.compilationService.compileModule(result)
        )
        .then((m: ModuleFactoryManifest) => {
          const wrappedModuleFactory = new WrapperNgModuleFactory(
            m.moduleFactory,
            this.envConfig,
            route.params,
            route.module,
            'dev'
          );
          return wrappedModuleFactory;
        });
    };
  }

  private loadChildrenForModuleByUrl(
    url: string,
    moduleName: string,
    moduleVersion: string,
    params: Record<string, unknown>,
    envConfig: EnvironmentConfig
  ) {
    return () => {
      return this.polarisService
        .loadAndCompileModule(`${url}`, moduleName)
        .then((m: ModuleFactoryManifest) => {
          const wrappedModuleFactory = new WrapperNgModuleFactory(
            m.moduleFactory,
            envConfig,
            params,
            moduleName,
            moduleVersion
          );
          return wrappedModuleFactory;
        });
    };
  }

  //////////////////////////////
  // HMR Handlers  /////////////
  //////////////////////////////

  private connectHMRForDevRoute(route: DevRoute, devServer: DevServer) {
    this.connectedDevServerRoute = route;

    let mostRecentCompilationHash: string;

    this.hmrService.connect(devServer.url, devServer.manifest.hmrPort, {
      hash: (message) => {
        if (
          mostRecentCompilationHash &&
          mostRecentCompilationHash !== message
        ) {
          window.location.reload();
          // TODO: This sucks.
          // Ideally we would trigger a hot reload of the whole app.
          // I have not figured out how to hijack that process yet.
        }
        mostRecentCompilationHash = message;
      },
    });
  }

  private connectHMRForManualDevRoute(
    manualDevAppManifest: ManualDevAppManifest
  ) {
    this.connectedManualDevRoute = manualDevAppManifest;

    let mostRecentCompilationHash: string;

    const parsedUrl = new URL(manualDevAppManifest.url);

    this.hmrService.connect(
      `${parsedUrl.protocol}://${parsedUrl.host}`,
      parseInt(parsedUrl.port, 10),
      {
        hash: (message) => {
          if (
            mostRecentCompilationHash &&
            mostRecentCompilationHash !== message
          ) {
            this.router.resetConfig(
              this.router.config.map((route) => {
                if (route.path === manualDevAppManifest.path) {
                  return this.routeForManualDevServerManifest(
                    manualDevAppManifest
                  );
                } else {
                  return route;
                }
              })
            );
            this.router.navigateByUrl(manualDevAppManifest.path, {
              skipLocationChange: true,
            });
          }
          mostRecentCompilationHash = message;
        },
      }
    );
  }

  private disconnectHMR() {
    this.hmrService.disconnect();
    this.connectedDevServerRoute = null;
  }
}
