import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { concatMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import {
  getMessagesStatusStoreState,
  getConfigStoreState,
  State,
} from '../reducer';
import { AlertsService } from '../../services/alerts.service';
import {
  MessagesStatusActions,
  MessagesStatusLoadAction,
  MessagesStatusLoadFailAction,
  MessagesStatusLoadSuccessAction,
} from './actions';
import {
  MessagesActions,
  MessagesLoadSuccessAction,
} from '../messages/actions';
import { MessagesStatusState } from './state';

interface NOOP {
  type: 'EMPTY';
}

@Injectable()
export class MessagesStatusEffects {
  @Effect()
  public loadStatus$: Observable<
    MessagesStatusLoadFailAction | MessagesStatusLoadSuccessAction
  > = this.actions$
    .pipe(
      ofType(MessagesStatusActions.Load),
      withLatestFrom(this.store.pipe(select(getConfigStoreState)))
    )
    .pipe(
      concatMap(([, config]) => {
        return this.alertsService.getStatus(config).pipe(
          map((payload) => new MessagesStatusLoadSuccessAction(payload)),
          catchError((error) => of(new MessagesStatusLoadFailAction(error)))
        );
      })
    );

  @Effect()
  public updateStatus$: Observable<
    MessagesStatusLoadAction | NOOP
  > = this.actions$
    .pipe(
      ofType(MessagesActions.LoadSuccess),
      withLatestFrom(this.store.pipe(select(getMessagesStatusStoreState)))
    )
    .pipe(
      map(
        ([action, status]: [
          MessagesLoadSuccessAction,
          MessagesStatusState
        ]) => {
          const newMessages = action.payload.messages;
          const numUnread = newMessages.filter((message) => !message.isRead)
            .length;

          if (
            status.loaded &&
            !status.loading &&
            numUnread > status.unreadCount
          ) {
            return new MessagesStatusLoadAction();
          } else {
            return { type: 'EMPTY' } as NOOP;
          }
        }
      )
    );

  constructor(
    private actions$: Actions,
    private alertsService: AlertsService,
    private store: Store<State>
  ) {}
}
