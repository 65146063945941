import {
  Component,
  ViewContainerRef,
  ViewChild,
  OnInit,
  Input,
  Output,
  ComponentRef,
  HostBinding,
  EventEmitter,
} from '@angular/core';
import { Observable } from 'rxjs';

import { CardType, ModuleFactories } from '../../types';
import { CardService } from './card.service';

@Component({
  selector: 'polaris-card',
  styles: ['.c-card-header { cursor: pointer }'],
  template: `
    <header class="c-card-header">
      <div class="c-card-header-title">
        {{ title }}
      </div>
    </header>
    <div class="c-card-content" style="position:relative;">
      <div
        *ngIf="!loaded"
        style="position:absolute;top:0;bottom:0;left:0;right:0;text-align:center;padding-top:30%;"
      >
        <i class="fa fa-spin fa-spinner"></i>
      </div>
      <span #content></span>
    </div>
  `,
})
export class CardComponent implements OnInit {
  @ViewChild('content', { read: ViewContainerRef })
  private content;

  @Input()
  @HostBinding('class')
  public classes: string;
  @HostBinding('style.display')
  public display = 'block';

  @Input()
  public context: CardType;
  @Input()
  public devUrl: string;
  @Input()
  public selector: string;
  @Input()
  public standalone = false;
  @Input()
  public override = 'northstar-cards-v1';
  @Output()
  public created = new EventEmitter<unknown>();

  public title = 'Loading...';
  public loaded = false;
  private component: ComponentRef<unknown>;

  constructor(private cardService: CardService) {}

  public ngOnInit() {
    this.initializeCardLoading();
  }

  public reloadCard(url: string, selector: string, context: CardType) {
    if (this.component) {
      this.component.destroy();
    }
    this.devUrl = url;
    this.selector = selector;
    this.context = context;
    this.standalone = true;
    this.initializeCardLoading();
  }

  private initializeCardLoading() {
    if (this.standalone && !this.devUrl) {
      this.cardService
        .loadModule(this.content, this.context, this.override)
        .then(this._afterContent.bind(this));
    } else if (this.standalone && this.devUrl) {
      this.cardService
        .loadModuleByUrl(
          this.content,
          this.devUrl,
          this.context.name,
          this.selector,
          this.context.params
        )
        .then(this._afterContent.bind(this));
    }
  }

  public setContentFactories(factories: ModuleFactories) {
    if (this.component) {
      this.component.destroy();
    }

    this.cardService
      .instantiateContent(this.content, this.context, factories)
      .then(this._afterContent.bind(this));
  }

  private _afterContent(component: ComponentRef<unknown>) {
    this.component = component;
    this.created.emit({ component, context: this.context });
    this.loaded = true;
    this._bindTitle(component);
  }

  private _bindTitle(
    component: ComponentRef<{ title?: string | Observable<string> }>
  ) {
    if (component.instance.title) {
      if (typeof component.instance.title === 'string') {
        this.title = component.instance.title;
      } else if (typeof component.instance.title.subscribe === 'function') {
        component.instance.title.subscribe((newTitle) => {
          this.title = newTitle;
        });
      }
    }
  }
}
