import { TransportsActions, TransportsActionsUnion } from './actions';
import { TransportsState } from './state';

export const INITIAL_STATE: TransportsState = {
  loaded: false,
  loading: false,
  transports: [],
  error: undefined
};

export function transportsReducer(
  state = INITIAL_STATE,
  action: TransportsActionsUnion
): TransportsState {
  switch (action.type) {
    case TransportsActions.Load:
      return {
        ...state,
        loading: true
      };
    case TransportsActions.LoadSuccess:
      return {
        ...state,
        loaded: true,
        loading: false,
        transports: action.payload,
        error: undefined
      };
    case TransportsActions.LoadFail:
      return {
        ...state,
        loaded: false,
        loading: false,
        transports: [],
        error: action.payload
      };
    default:
      return state;
  }
}
