import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  concatMap,
  switchMap,
  map,
  catchError,
  withLatestFrom,
} from 'rxjs/operators';
import { getConfigStoreState, State } from '../reducer';
import { AlertsService } from '../../services/alerts.service';
import {
  PreferencesActions,
  PreferencesLoadAction,
  PreferencesLoadFailAction,
  PreferencesLoadSuccessAction,
  PreferencesUpdateAction,
  PreferencesUpdateFailAction,
} from './actions';
import { MessagesLoadAction } from '../messages/actions';
import { MessagesStatusLoadAction } from '../messages-status/actions';
import { MESSAGE_LOAD_LIMIT } from '../../tokens';

@Injectable()
export class PreferencesEffects {
  @Effect()
  public loadPreferences$: Observable<
    PreferencesLoadFailAction | PreferencesLoadSuccessAction
  > = this.actions$
    .pipe(
      ofType(PreferencesActions.Load),
      withLatestFrom(this.store.pipe(select(getConfigStoreState)))
    )
    .pipe(
      concatMap(([, config]) => {
        return this.alertsService.getPreferences(config).pipe(
          map((payload) => new PreferencesLoadSuccessAction(payload)),
          catchError((error) => of(new PreferencesLoadFailAction(error)))
        );
      })
    );

  @Effect()
  public preferenceUpdate$: Observable<
    | PreferencesLoadAction
    | MessagesStatusLoadAction
    | MessagesLoadAction
    | PreferencesUpdateFailAction
  > = this.actions$
    .pipe(
      ofType(PreferencesActions.Update),
      withLatestFrom(this.store.pipe(select(getConfigStoreState)))
    )
    .pipe(
      concatMap(([action, config]) => {
        const payload = (action as PreferencesUpdateAction).payload;
        return this.alertsService.setPreference(payload, config).pipe(
          switchMap(() => [
            new PreferencesLoadAction(),
            new MessagesStatusLoadAction(),
            new MessagesLoadAction({ from: MESSAGE_LOAD_LIMIT }),
          ]),
          catchError((error) =>
            of(new PreferencesUpdateFailAction({ request: payload, error }))
          )
        );
      })
    );

  constructor(
    private actions$: Actions,
    private alertsService: AlertsService,
    private store: Store<State>
  ) {}
}
