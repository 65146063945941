import {
  Component,
  Input,
  HostBinding,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  HeaderDropdownParams,
  HeaderPathLinkParams,
} from '../header.component';

@Component({
  selector: 'polaris-header-dropdown',
  templateUrl: './header-dropdown.component.html',
  styleUrls: ['./header-dropdown.component.scss'],
})
export class HeaderDropdownComponent implements OnInit, OnDestroy {
  @HostBinding('class.c-header-item')
  item = true;

  @Input()
  public params: HeaderDropdownParams;

  @Input()
  public dropdownWidth = 200;

  @Input()
  public dropdownAlign = 'left';

  public active = new BehaviorSubject(false);

  public isDropdownVisible = false;

  private routerSubscription: Subscription;

  constructor(private router: Router) {}

  ngOnInit() {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof RoutesRecognized) {
        this.isDropdownVisible = false;
        this.active.next(this.urlMatchesMyTree(event.url));
      }
    });
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  public toggleDropdown() {
    this.isDropdownVisible = !this.isDropdownVisible;
  }

  private urlMatchesMyTree(url: string) {
    const isMyPath =
      (this.params as HeaderPathLinkParams).path === url.split('?')[0];

    return (
      isMyPath ||
      (this.params.menuItems || []).some((item) => {
        const path = (item as HeaderPathLinkParams).path;
        return url.startsWith(path) || url === path;
      })
    );
  }
}
