<a
  *ngIf="params.hasOwnProperty('path')"
  [routerLink]="params.path"
  class="c-header-item-link"
  [class.c-nav-link-active]="isLinkActive(params.path)"
  [ngClass]="(params.classes || []).join(' ')"
  [attr.data-automation-e2e-selector]="
    'nav-item-' + params.hasOwnProperty('name') ? params.name : ''
  "
>
  <ng-container *ngTemplateOutlet="innerContent"></ng-container>
</a>
<a
  *ngIf="params.hasOwnProperty('event')"
  href="javascript:void(0);"
  (click)="params.event($event)"
  class="c-nav-link"
  [ngClass]="(params.classes || []).join(' ')"
  [attr.data-automation-e2e-selector]="
    'nav-item-' + params.hasOwnProperty('name') ? params.name : ''
  "
>
  <ng-container *ngTemplateOutlet="innerContent"></ng-container>
</a>

<ng-template #innerContent>
  <ng-container *ngIf="params.hasOwnProperty('name')">
    <span
      *ngIf="params.icon"
      [ngClass]="'c-nav-icon fa fa-' + params.icon"
      aria-hidden="true"
      [attr.data-automation-e2e-selector]="'nav-item-icon-' + params.name"
    ></span>
    <span
      [attr.data-automation-e2e-selector]="'nav-item-title-' + params.name"
      >{{ params.name }}</span
    >
  </ng-container>
  <ng-container *ngIf="params.hasOwnProperty('innerHtml')">
    <span [innerHtml]="params.innerHtml"></span>
  </ng-container>
</ng-template>
