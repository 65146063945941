import * as homeSettings from './actions';
import { SettingsState } from './state';

export const INITIAL_STATE: SettingsState = {
  settings: {},
  loading: false,
  loaded: false,
  error: undefined,
  requests: {}
};

export function settingsReducer(
  state = INITIAL_STATE,
  action: homeSettings.SettingsActions
): SettingsState {
  switch (action.type) {
    case homeSettings.SETTINGS_FETCH:
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case homeSettings.SETTINGS_FETCH_SUCCESS:
      return {
        ...state,
        settings: action.payload,
        loaded: true,
        loading: false,
        error: undefined
      };
    case homeSettings.SETTINGS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case homeSettings.SETTINGS_SAVE:
      return {
        ...state,
        requests: {
          ...state.requests,
          [action.payload.requestId]: {
            loading: true,
            loaded: false,
            error: undefined
          }
        }
      };
    case homeSettings.SETTINGS_SAVE_SUCCESS:
      return {
        ...state,
        settings: action.payload.settings,
        requests: {
          ...state.requests,
          [action.payload.requestId]: {
            loading: false,
            loaded: true,
            error: undefined
          }
        }
      };
    case homeSettings.SETTINGS_SAVE_ERROR:
      return {
        ...state,
        requests: {
          ...state.requests,
          [action.payload.requestId]: {
            loading: false,
            loaded: false,
            error: action.payload.error
          }
        }
      };
    default:
      return state;
  }
}
