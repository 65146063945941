import { Component, Input, HostBinding } from '@angular/core';

import { HeaderInfoParams } from '../header.component';

@Component({
  selector: 'polaris-header-info',
  styles: [
    `
      .nav-info {
        cursor: not-allowed;
        opacity: 0.7;
      }
    `,
  ],
  templateUrl: './header-info.component.html',
})
export class HeaderInfoComponent {
  @HostBinding('class.c-header-item')
  item = true;

  @Input()
  public params: HeaderInfoParams;
}
