import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { NavigationSettings, Go, GO, BACK, FORWARD } from './actions';

@Injectable()
export class RouterEffects {
  @Effect({ dispatch: false })
  public navigate$: Observable<NavigationSettings> = this.actions$.pipe(
    ofType(GO),
    map((action: Go) => action.payload),
    tap(({ path, query: queryParams, extras }) =>
      this.router.navigate(path, { queryParams, ...extras })
    )
  );

  @Effect({ dispatch: false })
  public navigateBack$: Observable<Action> = this.actions$.pipe(
    ofType(BACK),
    tap(() => this.location.back())
  );

  @Effect({ dispatch: false })
  public navigateForward$: Observable<Action> = this.actions$.pipe(
    ofType(FORWARD),
    tap(() => this.location.forward())
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private location: Location
  ) {}
}
