<span>
  <ng-container *ngIf="params.hasOwnProperty('name')">
    <span
      class="c-nav-link nav-info"
      *ngIf="params.icon"
      [ngClass]="'c-nav-icon fa fa-' + params.icon"
      aria-hidden="true"
      [attr.data-automation-e2e-selector]="'nav-item-icon-' + params.name"
    ></span>
    <span
      class="c-nav-link nav-info"
      [attr.data-automation-e2e-selector]="'nav-item-title-' + params.name"
      >{{ params.name }}</span
    >
  </ng-container>
  <ng-container *ngIf="params.hasOwnProperty('innerHtml')">
    <span class="c-nav-link nav-info" [innerHtml]="params.innerHtml"></span>
  </ng-container>
</span>
