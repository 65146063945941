import { NgModuleFactory, ComponentFactory } from '@angular/core';
import { Route } from '@angular/router';
import { NMP } from '@ipreo/nmp-types';

export enum DevModuleType {
  route = 'route',
  component = 'component',
}

export interface DevModule extends Route {
  moduleType: DevModuleType;
  manifest: NMP.ModuleUploadManifest;
}

export interface RouteManifest {
  path: string;
  module: {
    name: string;
    version: string;
    params?: Record<string, unknown>;
    parameters?: Record<string, unknown>;
  };
}

export interface Navigation {
  name: string;
  icon: string;
  path: string;
  children?: Navigation[];
}

export interface NmpModule {
  name: string;
  version: string;
}

export interface ModuleFactoryManifest {
  moduleFactory: NgModuleFactory<unknown>;
  manifest: NMP.ModuleManifest;
}

export interface CardType {
  identifier: string;
  name: string;
  version: string;
  params?: Record<string, unknown>;
}
export interface DevCard extends CardType {
  selector: string;
  module: string;
  bundle: string;
}

export enum CardSupportedModes {
  'tiled',
  'full-width',
}

export interface CardModuleConfig {
  moduleName: string;
  selector: string;
  supportedModes: CardSupportedModes[];
}

export interface EnvironmentConfig {
  northstar: {
    host: {
      api: string;
      ws: string;
      registry: string;
    };
    env: string;
    cupcakeMajorVersion: number;
  };
  params?: Record<string, unknown>;
}

export interface ModuleFactories {
  moduleFactory: NgModuleFactory<unknown>;
  componentFactory: ComponentFactory<unknown>;
}

export interface DevRoute {
  module: string;
  path: string;
  params: Record<string, unknown>;
}

export interface DevServerManifest {
  hmrPort: number;
  navigation: Navigation[];
  routes: DevRoute[];
  modules: { [name: string]: NMP.PackageFileNMPMetadata };
  cards: DevCard[];
  defaultRoute?: string;
}

export interface ManualDevAppManifest {
  url: string;
  moduleName: string;
  navName: string;
  path: string;
  params: Record<string, unknown>;
}
