import { Injector, ErrorHandler } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '../../tokens';
import { LogService } from '../log/log.service';
import { LOG_SETTINGS, LogSettings } from '../log/log-settings';
import { AuthService } from '../auth.service';
import { HttpClient } from '@angular/common/http';
import { NorthstarErrorHandler } from '../log/error-handler.service';
import { EnvironmentConfig } from '../../types';

export class WrapperInjector {
  constructor(private moduleName?: string, private moduleVersion?: string) {}

  public getInjector(
    parentInjector: Injector | null,
    params: Record<string, unknown> = {}
  ): Injector {
    return Injector.create({
      providers: [
        {
          provide: ENVIRONMENT_CONFIG,
          useValue: {
            ...params,
          },
        },
        {
          provide: LogService,
          useFactory: (
            http: HttpClient,
            authService: AuthService,
            logSettings: LogSettings,
            apiConfig: EnvironmentConfig
          ) => {
            const logService = new LogService(
              http,
              logSettings,
              apiConfig,
              authService,
              this.moduleName,
              this.moduleVersion
            );
            logService.start();

            return logService;
          },
          deps: [HttpClient, AuthService, LOG_SETTINGS, ENVIRONMENT_CONFIG],
        },
        {
          provide: ErrorHandler,
          useClass: NorthstarErrorHandler,
          deps: [LogService],
        },
      ],
      parent: parentInjector,
    });
  }
}
