import { Action } from '@ngrx/store';
import { ManifestFetchResults, ManifestFetchResult } from './state';

export const DEV_SERVERS_FETCH_MANIFESTS = '[dev-servers] Fetch Manifests';
export const DEV_SERVERS_FETCH_MANIFESTS_SUCCESS =
  '[dev-servers] Fetch Manifests Success';

export const DEV_SERVERS_ADD = '[dev-servers] Add Dev Server';
export const DEV_SERVERS_ADD_SUCCESS = '[dev-servers] Add Dev Server Success';

export const DEV_SERVERS_REMOVE = '[dev-servers] Remove Dev Server';
export const DEV_SERVERS_CLEAR = '[dev-servers] Clear Dev Servers';

/* tslint:disable:max-classes-per-file */

export class FetchDevServerManifestsAction implements Action {
  public readonly type = DEV_SERVERS_FETCH_MANIFESTS;
}

export class FetchDevServerManifestsSuccessAction implements Action {
  public readonly type = DEV_SERVERS_FETCH_MANIFESTS_SUCCESS;

  constructor(public payload: ManifestFetchResults) {}
}

export class AddDevServerAction implements Action {
  public readonly type = DEV_SERVERS_ADD;

  constructor(public payload: string) {}
}

export class AddDevServerSuccessAction implements Action {
  public readonly type = DEV_SERVERS_ADD_SUCCESS;

  constructor(public payload: ManifestFetchResult) {}
}

export class RemoveDevServerAction implements Action {
  public readonly type = DEV_SERVERS_REMOVE;

  constructor(public payload: string) {}
}

export class ClearDevServerAction implements Action {
  public readonly type = DEV_SERVERS_CLEAR;
}

export type DevServerActions =
  | FetchDevServerManifestsAction
  | FetchDevServerManifestsSuccessAction
  | AddDevServerAction
  | AddDevServerSuccessAction
  | RemoveDevServerAction
  | ClearDevServerAction;
