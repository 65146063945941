import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { User } from 'oidc-client';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private user: User;

  constructor(private auth: AuthService) {
    auth.userLoadedEvent.subscribe((user: User) => {
      this.user = user;
    });

    if (auth.currentUser) {
      this.user = auth.currentUser;
    }
  }

  public intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let authReq;

    if (req.headers.has('Authorization')) {
      authReq =
        req.headers.get('Authorization') === 'none'
          ? req.clone({
              headers: req.headers.delete('Authorization'),
            })
          : req;
    } else {
      authReq = this.user
        ? req.clone({
            headers: req.headers.append(
              'Authorization',
              `${this.user.token_type} ${this.user.access_token}`
            ),
          })
        : req;
    }

    return next
      .handle(authReq)
      .pipe(catchError((err) => this.sessionChecker(err)));
  }

  private sessionChecker(err: unknown) {
    if (err instanceof HttpErrorResponse && err.status === 401) {
      this.auth.checkSession();
    }

    return throwError(err);
  }
}
