import {
  Injectable,
  ViewContainerRef,
  ComponentRef,
  Injector,
  Inject,
} from '@angular/core';
import { Observable, of, combineLatest } from 'rxjs';
import { map, distinctUntilChanged, take } from 'rxjs/operators';
import { NMP } from '@ipreo/nmp-types';
import { LoaderService } from './loader.service';
import { Store, select } from '@ngrx/store';
import { State } from '../state/reducer';
import { DevServersState, DevServer } from '../state/dev-servers/state';
import { getDevServersState } from '../state/reducer';
import { DEV_MODULES } from '../tokens';
import {
  ModuleFactories,
  ModuleFactoryManifest,
  DevModule,
  DevModuleType,
} from '../types';
import { CompilationService } from './compilation.service';

/*
Module loading:
1. Load via URL - dead end, no dependencies, just get the module and load it into SystemJS
2. Load via NMP name/version -
  A. If named module exists in the local modules list then recursively load including any dependencies
  B. If the named module doesn't exist, load via the default NMP loader.
*/
interface DevModuleManifestAdditions {
  baseUrl: string;
}

type DevModuleManifest = DevModuleManifestAdditions &
  NMP.PackageFileNMPMetadata;

@Injectable()
export class PolarisService {
  private devServers: Observable<DevServer[]> = this.store.pipe(
    select(getDevServersState),
    map((state: DevServersState) =>
      state.servers.filter((server: DevServer) => server.enabled)
    ),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
  );

  private devServerModules: Observable<{
    [name: string]: DevModuleManifest;
  }> = this.devServers.pipe(
    map((devServers) =>
      devServers.reduce(
        (acc, devServer) => ({
          ...acc,
          ...this.addBaseUrlToModules(
            devServer.url,
            devServer.manifest.modules
          ),
        }),
        {}
      )
    )
  );

  private devComponents: { [name: string]: DevModule };

  constructor(
    private store: Store<{ northstar: State }>,
    private compilationService: CompilationService,
    private loaderService: LoaderService,
    @Inject(DEV_MODULES) devModules: DevModule[]
  ) {
    this.devComponents = devModules
      .filter((module) => module.moduleType === DevModuleType.component)
      .reduce((acc, devComponent) => {
        if (devComponent.manifest) {
          acc[devComponent.manifest.name] = devComponent;
        }
        return acc;
      }, {});
  }

  public async loadModule(
    name: string,
    version: string
  ): Promise<NMP.LoaderResponse> {
    const devServerModules = await this.latestDevModules();

    if (devServerModules[name]) {
      return this.loadModuleByManifest(devServerModules[name], name);
    } else if (this.devComponents[name]) {
      return this.loadDevModule(this.devComponents[name]);
    } else {
      return this.loaderService.loadModule(name, version);
    }
  }

  public async loadModules(
    modules: Array<{ name: string; version: string }>
  ): Promise<Array<NMP.LoaderResponse>> {
    const devServerModules = await this.latestDevModules();
    const promises: Promise<NMP.LoaderResponse>[] = [];
    const nonOverridenModules = [];

    modules.forEach((module) => {
      if (devServerModules[module.name]) {
        promises.push(
          this.loadModuleByManifest(devServerModules[module.name], module.name)
        );
      } else if (this.devComponents[module.name]) {
        promises.push(this.loadDevModule(this.devComponents[module.name]));
      } else {
        nonOverridenModules.push(module);
      }
    });

    return Promise.all(promises).then(async (responses) => {
      const loaderResponses = nonOverridenModules.length
        ? await this.loaderService.loadModules(nonOverridenModules)
        : [];

      return modules.map((module) => {
        const overloadedModule = responses.find(
          (response) => response.manifest.name === module.name
        );
        const regularModule = loaderResponses.find(
          (response) =>
            response.manifest.name === module.name &&
            response.manifest.version === module.version
        );

        return overloadedModule || regularModule;
      });
    });
  }

  public loadModuleByUrl(
    url: string,
    moduleName: string
  ): Promise<ModuleFactoryManifest> {
    return this.loadAndCompileModule(url, moduleName);
  }

  public async loadAndCompileModule(
    url: string,
    moduleName: string
  ): Promise<ModuleFactoryManifest> {
    const module = await this.loaderService.loadByUrl(url);

    const moduleFactory = await this.compilationService.baseCompileModuleAsync(
      module[moduleName]
    );

    return {
      moduleFactory: moduleFactory,
      manifest: undefined,
    } as ModuleFactoryManifest;
  }

  public async loadAngularComponent(
    northstarModule: string,
    northstarModuleVersion: string,
    manifestLoadHandler?: (
      manifest: NMP.ModuleManifest
    ) => NMP.AngularModuleManifest
  ): Promise<ModuleFactories> {
    const response = await this.loadModule(
      northstarModule,
      northstarModuleVersion
    );

    return this.compilationService.compileAngularComponent(
      response,
      manifestLoadHandler
    );
  }

  public async loadAngularComponents(
    moduleArray: { name: string; version: string }[],
    manifestLoadHandler?: (
      manifest: NMP.ModuleManifest
    ) => NMP.AngularModuleManifest,
    errorHandler?: (
      err: unknown,
      module: NMP.ModuleManifest
    ) => ModuleFactories | Promise<ModuleFactories>
  ): Promise<ModuleFactories[]> {
    const responses = await this.loadModules(moduleArray);

    return this.compilationService.compileAngularComponents(
      responses,
      manifestLoadHandler,
      errorHandler
    );
  }

  // this one is primary API to load nmp module just by its name and version
  // manifestLoadHandler parameter is optional in case when you want to load non-default ngModule and/or components from the NMP module
  public async loadAndCreateAngularComponent(
    viewref: ViewContainerRef,
    northstarModule: string,
    northstarModuleVersion: string,
    injector: Injector | null,
    manifestLoadHandler?: (
      manifest: NMP.ModuleManifest
    ) => NMP.AngularModuleManifest
  ): Promise<ComponentRef<unknown>> {
    const response = await this.loadModule(
      northstarModule,
      northstarModuleVersion
    );

    return this.compilationService.compileAndCreateAngularComponent(
      response,
      viewref,
      injector,
      manifestLoadHandler
    );
  }

  // this one is generic method to load any UMD module that contains angular module with component to instantiate
  public async loadAndCreateAngularComponentByUrl(
    viewref: ViewContainerRef,
    url: string,
    moduleName: string,
    selector: string,
    injector: Injector | null
  ): Promise<ComponentRef<unknown>> {
    const module = await this.loaderService.loadByUrl(url);

    return this.compilationService.compileAndInstantiateModuleAndComponent(
      viewref,
      module,
      url,
      { moduleName, selector },
      injector
    );
  }

  private async loadModuleByManifest(
    manifest: DevModuleManifest,
    name: string
  ): Promise<NMP.LoaderResponse> {
    await this.loadModules(
      this.dependenciesToArray(manifest.externalDependencies)
    );

    const module = await this.loaderService.loadByUrl(
      `${manifest.baseUrl}${manifest.jsBundle}`,
      name
    );

    return {
      module,
      manifest: {
        name,
        version: 'dev',
        platforms: manifest.platforms,
      },
    } as NMP.LoaderResponse;
  }

  private async loadDevModule(
    devModule: DevModule
  ): Promise<NMP.LoaderResponse> {
    await this.loadModules(
      this.dependenciesToArray(devModule.manifest.externalDependencies)
    );

    const module = await (devModule.loadChildren as () => unknown)();

    return {
      module,
      manifest: {
        name: devModule.manifest.name,
        version: 'dev',
        platforms: devModule.manifest.platforms,
      },
    } as NMP.LoaderResponse;
  }

  private addBaseUrlToModules(
    manifestUrl: string,
    modules: { [name: string]: NMP.PackageFileNMPMetadata } = {}
  ): { [name: string]: DevModuleManifest } {
    const url = new URL(manifestUrl);
    const basePath = url.pathname.match(/.json$/)
      ? url.pathname.split('/').slice(0, -1).join('/')
      : url.pathname; // Remove the manifest itself
    const baseUrl = `${url.origin}${basePath.replace(/\/$/, '')}`;
    const finalModules = {};
    for (const moduleName in modules) {
      if (Object.prototype.hasOwnProperty.call(modules, moduleName)) {
        finalModules[moduleName] = {
          ...modules[moduleName],
          baseUrl,
        };
      }
    }
    return finalModules;
  }

  private latestDevModules(): Promise<{
    [name: string]: DevModuleManifest;
  }> {
    return combineLatest([of('event'), this.devServerModules])
      .pipe(
        take(1),
        map(([, devServerModules]) => devServerModules)
      )
      .toPromise();
  }

  private dependenciesToArray(dependencies: { [name: string]: string }) {
    const dependencyArray = [];
    for (const name in dependencies) {
      if (Object.prototype.hasOwnProperty.call(dependencies, name)) {
        dependencyArray.push({ name, version: dependencies[name] });
      }
    }
    return dependencyArray;
  }
}
