/* eslint-disable */
// Import tiny dependency directly from https://github.com/lukeed/sockette/blob/v2.0.6/src/index.js
// We were burned by a patch version update from 2.0.5 -> 2.0.6 and the library hasn't been updated for a long time anyway.

function noop() {}

export type SocketteOptions = {
  maxAttempts?: number;
  protocols?: string[];
  onmessage?: (ev: MessageEvent<any>) => any;
  onopen?: (ev: Event) => any;
  onerror?: (ev: Event) => any;
  onclose?: (ev: CloseEvent) => any;
  onreconnect?: (ev: CloseEvent | Event) => any;
  onmaximum?: (ev: CloseEvent | Event) => any;
  timeout?: number;
};

export class Sockette {
  private ws: WebSocket;
  private num = 0;
  private timer: ReturnType<typeof setTimeout>;
  private max: number;

  constructor(private url: string, private opts: SocketteOptions = {}) {
    this.max = opts.maxAttempts || Infinity;

    this.open();
  }

  public open() {
    this.ws = new WebSocket(this.url, this.opts.protocols || []);

    this.ws.onmessage = this.opts.onmessage || noop;

    this.ws.onopen = (e) => {
      (this.opts.onopen || noop)(e);
      this.num = 0;
    };

    this.ws.onclose = (e) => {
      e.code === 1e3 || e.code === 1001 || e.code === 1005 || this.reconnect(e);
      (this.opts.onclose || noop)(e);
    };

    this.ws.onerror = (e) => {
      e && e['code'] === 'ECONNREFUSED'
        ? this.reconnect(e)
        : (this.opts.onerror || noop)(e);
    };
  }

  public json(value: any) {
    this.ws.send(JSON.stringify(value));
  }

  public send(value: any) {
    this.ws.send(value);
  }

  public close(code?: number, reason?: string) {
    clearTimeout(this.timer);
    this.timer = null;
    this.ws.close(code || 1e3, reason);
  }

  public reconnect(e?: CloseEvent | Event) {
    if (this.num++ < this.max) {
      this.timer = setTimeout(() => {
        (this.opts.onreconnect || noop)(e);
        this.open();
      }, this.opts.timeout || 1e3);
    } else {
      (this.opts.onmaximum || noop)(e);
    }
  }
}
