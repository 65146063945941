import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { ConfigActions, SetAlertsTokenAction } from './actions';
import { MessagesStatusLoadAction } from '../messages-status/actions';

@Injectable()
export class ConfigEffects {
  @Effect()
  public loadMessages$: Observable<
    MessagesStatusLoadAction
  > = this.actions$.pipe(
    ofType(ConfigActions.SetToken),
    filter((action: SetAlertsTokenAction) => !!action.payload),
    map(() => new MessagesStatusLoadAction())
  );

  constructor(private actions$: Actions) {}
}
