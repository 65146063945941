import { OnDestroy, OnInit, Component, Input, Inject } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';
import { Observable, Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import {
  WebsocketGatewayService,
  ENVIRONMENT_CONFIG,
  EnvironmentConfig
} from '@ipreo/northstar';
import { Message } from '../../types';
import { MESSAGE_LOAD_LIMIT } from '../../tokens';
import {
  getMessagesStoreState,
  getMessagesStatusStoreState,
  State
} from '../../state/reducer';
import { MessagesStatusState } from '../../state/messages-status/state';
import { MessagesState } from '../../state/messages/state';
import {
  MessagesAddAction,
  MessagesLoadAction,
  MessagesReadAction,
  MessagesReadAllAction
} from '../../state/messages/actions';

@Component({
  selector: 'northstar-alerts-pane',
  templateUrl: './pane.component.html',
  styleUrls: ['./pane.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'void',
        style({
          left: '420px',
          right: '-400px'
        })
      ),
      transition('void <=> *', animate('300ms 0s ease'))
    ]),
    trigger('fadeSlideInOut', [
      state(
        'void',
        style({
          overflow: 'hidden',
          height: '0px',
          opacity: '0'
        })
      ),
      transition('void <=> *', animate('300ms 0s ease'))
    ]),
    trigger('fadeInOut', [
      state(
        'void',
        style({
          opacity: '0'
        })
      ),
      transition('void <=> *', animate('300ms 0s ease'))
    ])
  ]
})
export class PaneComponent implements OnDestroy, OnInit {
  @Input()
  public visible = false;

  public filteredToUnread$ = new BehaviorSubject<boolean>(false);

  public messagesState$: Observable<MessagesState> = this.store.pipe(
    select(getMessagesStoreState)
  );

  public messages$: Observable<Message[]> = combineLatest([
    this.messagesState$,
    this.filteredToUnread$
  ]).pipe(
    map(([messagesState, filtered]) => {
      return filtered
        ? messagesState.messages.filter(message => !message.isRead)
        : messagesState.messages;
    })
  );

  public messagesStatus$: Observable<MessagesStatusState> = this.store.pipe(
    select(getMessagesStatusStoreState)
  );

  public settingsOpen = false;

  public cupcakeMajorVersion: number;

  private messagesCount: number;

  private websocketSubscription: Subscription;
  private messagesSubscription: Subscription;

  constructor(
    private store: Store<State>,
    private websocketGatewayService: WebsocketGatewayService,
    @Inject(ENVIRONMENT_CONFIG) config: EnvironmentConfig
  ) {
    this.cupcakeMajorVersion = config.northstar.cupcakeMajorVersion;
  }

  ngOnInit() {
    this.websocketSubscription = this.websocketGatewayService
      .subscribe('alerts')
      .subscribe(message => {
        this.store.dispatch(new MessagesAddAction(message as Message));
      });

    this.messagesSubscription = this.messagesState$.subscribe(
      ({ messages }) => {
        this.messagesCount = messages.length;
      }
    );

    this.store.dispatch(
      new MessagesLoadAction({
        size: MESSAGE_LOAD_LIMIT
      })
    );
  }

  ngOnDestroy(): void {
    if (this.websocketSubscription) {
      this.websocketSubscription.unsubscribe();
    }
    if (this.messagesSubscription) {
      this.messagesSubscription.unsubscribe();
    }
  }

  public toggleRead(message: Message, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.store.dispatch(
      new MessagesReadAction({
        deliveryIds: [message.deliveryId],
        read: !message.isRead
      })
    );
  }

  public markAllAsRead() {
    this.store.dispatch(new MessagesReadAllAction());
  }

  public loadMore() {
    this.store.dispatch(
      new MessagesLoadAction({
        size: MESSAGE_LOAD_LIMIT,
        from: this.messagesCount
      })
    );
  }

  public showSettings() {
    this.settingsOpen = true;
  }

  public hideSettings = () => {
    this.settingsOpen = false;
  };

  public viewAll() {
    this.filteredToUnread$.next(false);
  }

  public filterToUnread() {
    this.filteredToUnread$.next(true);
  }

  public trackById(_index, message: Message) {
    return message.messageGuid;
  }

  public click(message: Message) {
    this.store.dispatch(
      new MessagesReadAction({
        deliveryIds: [message.deliveryId],
        read: true
      })
    );

    window.location.assign(message.uri);
  }
}
