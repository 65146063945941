<div class="ns-alerts" *ngIf="messagesStatus$ | async as status">
  <span *ngIf="status.loading || !status.loaded"
    class="ns-alerts-loading c-text-gray-5 fa fa-spin fa-circle-o-notch"></span>
  <a *ngIf="!status.loading && status.loaded" (click)="togglePane($event)">
    <span class="c-avatar" [ngClass]="{
        'c-avatar-primary': status.unreadCount === 0,
        'c-avatar-danger': status.unreadCount > 0,
        'c-avatar-sm': cupcakeMajorVersion === 1
      }" [attr.data-text]="status.unreadCount">
    </span></a>
</div>
<northstar-alerts-pane *ngIf="paneVisible" [@slideInOut]></northstar-alerts-pane>