import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import {
  exhaustMap,
  map,
  catchError,
  withLatestFrom,
  mergeMap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { DevServerManifest } from '../../types';
import { State, getDevServersState } from '../reducer';

import { ManifestFetchResult } from './state';
import * as actions from './actions';

const DEFAULT_MANIFEST_URI = 'polaris-manifest.json';

@Injectable()
export class DevServersEffects {
  @Effect()
  public loadManifests$ = this.actions$.pipe(
    ofType(actions.DEV_SERVERS_FETCH_MANIFESTS),
    withLatestFrom(this.store),
    map(([, store]) => getDevServersState(store).servers),
    mergeMap((servers) =>
      forkJoin(servers.map((server) => this.fetchManifest(server.url)))
    ),
    map((serverResults: ManifestFetchResult[]) =>
      serverResults.reduce((acc, serverResult: ManifestFetchResult) => {
        acc[serverResult.url] = {
          manifest: serverResult.manifest,
          error: serverResult.error,
        };
        return acc;
      }, {})
    ),
    map(
      (resultMap) => new actions.FetchDevServerManifestsSuccessAction(resultMap)
    )
  );

  @Effect()
  public loadManifest$ = this.actions$.pipe(
    ofType(actions.DEV_SERVERS_ADD),
    exhaustMap((action: actions.AddDevServerAction) =>
      this.fetchManifest(action.payload).pipe(
        map((result) => new actions.AddDevServerSuccessAction(result))
      )
    )
  );

  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private store: Store<State>
  ) {}

  private fetchManifest(url: string): Observable<ManifestFetchResult> {
    const fullUrl = this.normalizeUrl(url);
    return this.http
      .get<DevServerManifest>(fullUrl, {
        headers: { Authorization: 'none' },
      })
      .pipe(
        map((res) => ({
          url,
          manifest: res,
        })),
        catchError((err) =>
          of({
            url,
            error: err.message,
          })
        )
      );
  }

  private normalizeUrl(url: string) {
    if (url.match(/\.json$/)) {
      return url;
    } else {
      return `${url.endsWith('/') ? url : url + '/'}${DEFAULT_MANIFEST_URI}`;
    }
  }
}
