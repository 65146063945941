import { Action } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';

export const GO = '[Router] Go';
export const BACK = '[Router] Back';
export const FORWARD = '[Router] Forward';

export interface NavigationSettings {
  path: unknown[];
  query?: Record<string, string>;
  extras?: NavigationExtras;
}

export class Go implements Action {
  public readonly type = GO;

  constructor(public payload: NavigationSettings) {}
}

export class Back implements Action {
  public readonly type = BACK;
}

export class Forward implements Action {
  public readonly type = FORWARD;
}
