import { UserManagerSettings } from 'oidc-client';

export const environment = {
  production: true,
  env: 'staging',
  hmr: false,
  registryUrl: 'https://registry.staging.browsermodules.ihsmarkit.com',
  apiBaseUrl: 'https://api.staging.polaris.ihsmarkit.com',
  wsUrl: 'wss://gateway.staging.sockets.ipreo.com/connect',
  enableRouterTracing: false,
  instrumentStore: false,
  loadLocalDevModules: false,
  oidcSettings: {
    authority:
      'https://sam.samexternal.net/sso/oauth2/realms/root/realms/Customers',
    client_id: 'GlobalMarkets-Polaris-Staging-tFIVdQYlUq',
    redirect_uri: 'https://staging.globalmarkets.ihsmarkit.com',
    post_logout_redirect_uri: 'https://staging.globalmarkets.ihsmarkit.com',
    response_type: 'code',
    scope: 'openid email profile',
    automaticSilentRenew: true,
    silentRequestTimeout: 10000,
    filterProtocolClaims: true,
    loadUserInfo: true,
  } as UserManagerSettings,
};
