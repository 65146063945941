import { Component, HostBinding } from '@angular/core';
import { DashboardService } from './dashboard.service';

@Component({
  selector: 'polaris-dashboard',
  template: `
    <div class="c-p-lg">
      <h3>
        You don't have access to any applications. Please contact support.
      </h3>
    </div>
  `,
})
export class DashboardComponent {
  @HostBinding('class.ns-card-container')
  public 'true';

  public cards$ = this.dashboardService.cards$;

  constructor(private dashboardService: DashboardService) {}

  public positionChange(data: { positions: { id: string }[] }) {
    this.dashboardService.updatePositions(data.positions);
  }
}
