import { environment } from './../environments/environment';

import * as tslib from 'tslib';
import * as angularCdk from '@angular/cdk';
import * as angularCore from '@angular/core';
import * as angularCommon from '@angular/common';
import * as angularAnimations from '@angular/animations';
import * as angularAnimationsBrowser from '@angular/animations/browser';
import * as angularCommonHttp from '@angular/common/http';
import * as angularForms from '@angular/forms';
import * as angularPlatformBrowserAnimations from '@angular/platform-browser/animations';
import * as angularPlatformBrowserDynamic from '@angular/platform-browser-dynamic';
import * as angularPlatformBrowser from '@angular/platform-browser';
import * as angularRouter from '@angular/router';
import * as angularCompiler from '@angular/compiler';
import * as rxjs from 'rxjs';
import * as moment from 'moment';
import * as rxjsOperators from 'rxjs/operators';
import * as northstar from '@ipreo/northstar';
import * as ngrxStore from '@ngrx/store';
import * as ngrxEffects from '@ngrx/effects';
import * as ngrxRouterStore from '@ngrx/router-store';
import * as sprinkles from '@ipreo/ngx-sprinkles';
import * as cupcakeComponents from '@ipreo/cupcake-components';

console.log('BOOT IT', environment);

nmp.getLoader(environment.registryUrl);

nmp.getLoader().register('tslib', tslib);
nmp.getLoader().register('@angular/cdk', angularCdk);
nmp.getLoader().register('@angular/core', angularCore);
nmp.getLoader().register('@angular/common', angularCommon);
nmp.getLoader().register('@angular/animations', angularAnimations);
nmp.getLoader().register('@angular/platform-browser', angularPlatformBrowser);
nmp.getLoader().register('@angular/router', angularRouter);
nmp.getLoader().register('@angular/compiler', angularCompiler);
nmp
  .getLoader()
  .register('@angular/animations/browser', angularAnimationsBrowser);
nmp.getLoader().register('@angular/common/http', angularCommonHttp);
nmp.getLoader().register('@angular/forms', angularForms);
nmp
  .getLoader()
  .register(
    '@angular/platform-browser/animations',
    angularPlatformBrowserAnimations
  );
nmp
  .getLoader()
  .register('@angular/platform-browser-dynamic', angularPlatformBrowserDynamic);
nmp.getLoader().register('rxjs', rxjs);
nmp.getLoader().register('rxjs/operators', rxjsOperators);
nmp.getLoader().register('moment', moment);
nmp.getLoader().register('@ipreo/cupcake-components', cupcakeComponents);
nmp.getLoader().register('@ipreo/ngx-sprinkles', sprinkles);
nmp.getLoader().register('@ipreo/northstar', northstar);
nmp.getLoader().register('@ngrx/store', ngrxStore);
nmp.getLoader().register('@ngrx/effects', ngrxEffects);
nmp.getLoader().register('@ngrx/router-store', ngrxRouterStore);
