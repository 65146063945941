import * as featuresActions from './actions';
import { FeaturesState } from './state';

export const INITIAL_STATE: FeaturesState = {
  loaded: false,
  navigation: [],
  routes: [],
  cards: [],
};

export function featuresReducer(
  state = INITIAL_STATE,
  action: featuresActions.Actions
): FeaturesState {
  switch (action.type) {
    case featuresActions.FEATURES_FETCH_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        routes: action.payload.routes,
        cards: action.payload.dashboardCards,
        navigation: action.payload.navigation,
        defaultRoute: action.payload.defaultRoute,
      });
    case featuresActions.FEATURES_NEW_ROUTE:
      return {
        ...state,
        navigation: [
          ...state.navigation,
          ...[].concat(action.payload.navigation),
        ],
      };
    case featuresActions.FEATURES_RESET_ROUTE:
      // eslint-disable-next-line no-case-declarations
      const newNavigationState = state.navigation.filter(
        (nav) => nav.name !== action.applicationName
      );
      return {
        ...state,
        navigation: [...newNavigationState],
      };
    case featuresActions.FEATURES_FETCH_ERROR:
      return INITIAL_STATE;
    default:
      return state;
  }
}
