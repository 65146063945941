import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { concatMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { getConfigStoreState, State } from '../reducer';
import { AlertsService } from '../../services/alerts.service';
import {
  MessageTypesActions,
  MessageTypesLoadFailAction,
  MessageTypesLoadSuccessAction,
} from './actions';

@Injectable()
export class MessageTypesEffects {
  @Effect()
  public loadStatus$: Observable<
    MessageTypesLoadFailAction | MessageTypesLoadSuccessAction
  > = this.actions$
    .pipe(
      ofType(MessageTypesActions.Load),
      withLatestFrom(this.store.pipe(select(getConfigStoreState)))
    )
    .pipe(
      concatMap(([, config]) => {
        return this.alertsService.getMessageTypes(config).pipe(
          map((payload) => new MessageTypesLoadSuccessAction(payload)),
          catchError((error) => of(new MessageTypesLoadFailAction(error)))
        );
      })
    );

  constructor(
    private actions$: Actions,
    private alertsService: AlertsService,
    private store: Store<State>
  ) {}
}
